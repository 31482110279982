      
html, body { 
    scroll-behavior: smooth
  }
img {display: block;}
body {font-family: "Montserrat","Lato", sans-serif; font-size: 18px; color:#000000; line-height: 1.5; overflow-x: hidden;  font-weight:300; padding-top:120px;}
.clearfix:after {display: block; content: ""; clear: both;}
.clear {clear:both;}
.container {max-width:1640px; padding:0px 100px;}
input[type=text] {font-family: "Montserrat", sans-serif; font-size: 16px; width:100%; height:35px; border-radius: 6px; outline: none; border:none; padding:10px;}
textarea {font-family: "Montserrat", sans-serif; font-size: 16px; width:100%; height:44px; border-radius: 6px; outline: none; border:none; padding:13px 10px 10px 10px;}
.tbl_wrapper {overflow-x:auto;}

.frm_cnt {display: block; position: relative; padding-left: 35px; margin-bottom: 12px; cursor: pointer; font-size: 22px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
.frm_cnt input {position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0;}
.checkmark {position: absolute; top: 0; left: 0; height: 40px; width: 40px; border: 1px solid #D9D9D9; border-radius: 5px;}

.checkmark:after {content: ""; position: absolute; display: none;}
.frm_cnt input:checked ~ .checkmark:after {display: block;}
.frm_cnt .checkmark:after {left: 13px; top: 6px; width: 8px; height: 16px; border: solid #7D65AC; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);}
header .container.headthanks {
    max-width: 1520px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
}
  
.inputfile input[type="file"]{display: none;}
.inputfile label{cursor:pointer;}
.inputfile #name_ct {color: #7d65ac; position: absolute; top: 0px; left: 10px; background: #fff; height: 100%; padding-top: 16px; width: calc(100% - 210px); display: none; overflow: hidden;}
.inputfile #imageName {display: block; width: 4000px; font-weight: 500;}


.btn_1 {font-size: 16px; font-weight: 500; line-height: 1; text-align: center; color:#ffffff; background-color: #7D65AC; height:52px; display: block; border-radius: 26px; padding-top:18px; transition: 0.5s;}

.menu-open {overflow: hidden; padding-right:17px;}



.owl-carousel .owl-stage{display: flex;}
.owl-carousel .item {height: 100%;}
.owl-theme .owl-dots .owl-dot span {margin: 5px 5px !important; background: transparent !important; border: 1px solid #000;}
.owl-next {position: absolute; width: 38px; height: 38px; top: 50%; right: -68px;}
.owl-prev {position: absolute; width: 38px; height: 38px; top: 50%; left: -68px;}
.owl-next span, .owl-prev span {display: none;}
.owl-theme .owl-dots, .owl-theme .owl-nav {display:none;}

.title {text-align: center;}

.title h1 {font-size: 32px; font-weight: 700; line-height: 1.2; margin-bottom:10px;}
.title h1 span {color:#7D65AC;}
.compulsory-sec p{color:#7D65AC;font-size:12px;text-align:center;font-weight:600;}
.back-button{background-color:#7D65AC;color:#fff;border:none;width:30px;height:30px;margin:0 10px 0 10px;border-radius:50%;display:flex;align-items:center;justify-content:center;cursor:pointer;transition:all 0.3s ease;position:relative}
/* .back-button::before{content:"\2190";font-size:16px;font-weight:bold} */
.back-button:hover{background-color:#6a4f96}


.title h2 {font-size: 32px; font-weight: 700; line-height: 1.2; margin-bottom:10px;}
.title h2 span {color:#7D65AC;}
.title h3 {font-family: Lato; font-size: 22px; font-weight: 400; line-height: 1.2; margin-bottom: 40px;}
.title h4 {font-size: 18px; font-weight: 600; line-height: 1.5; margin-bottom: 20px;}

.popup_video {position:fixed; top:0px; left:0px; width: 100%; height:100%; background-color: rgba(0,0,0,0.3); z-index:999999; display: block;}
.popup_video .dv {position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); height: 90%; background-color: #353535; text-align: center; padding:60px 10px 10px 10px;}
.popup_video .dv .cl {position:absolute; top:0px; right:0px; padding:20px; cursor:pointer;}

.modal-open {padding-right: 17px; overflow: hidden;}

.popup_call {padding:40px; position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; overflow-y: scroll; background: rgba(0, 0, 0, 0.5); z-index: 999999;}
.popup_call .ct {max-width: 1260px; position:relative; display:flex; background-color: #ffffff; border-radius: 20px; overflow:hidden; transform: scale(0.8); transform-origin: top center; margin-bottom:-600px;}
.popup_call .ct2 {position: relative;  background-color: #ffffff; border-radius: 20px; overflow:hidden; padding:70px 40px 60px 40px;}
.popup_call .lt {width:50%; margin:0px; background: url(/public/images/pop_pic1.jpg) no-repeat center; background-size: cover;}
.popup_call .rt {position:relative; width:50%; margin:0px; padding:70px 40px 60px 40px;}
.popup_call .rt .t1 {font-size: 26px; font-weight: 700; line-height: 1.2; margin-bottom: 10px;}
.popup_call .rt .t1 span {color: #7D65AC;}
.popup_call .rt .t2 {font-size: 16px; font-weight: 600; line-height: 1.2; margin-bottom: 10px;}
.popup_call .rt .t2 span {color: #7D65AC; font-weight: 700;}
.popup_call .rt .bx {position:relative; border: 1px solid #D9D9D9; border-radius: 10px; overflow: hidden; padding-left:50px; padding-right: 10px; margin-bottom:20px;}
.popup_call .rt .bx.ph {padding-left:90px;}
.popup_call .rt .bx .ic {position:absolute; top:0px; left:0px; height:56px; width:56px; display: flex; align-items: center; justify-content: center;}
.popup_call .rt .bx .cc {position:absolute; top:6px; left:50px; font-size: 20px; font-weight: 600; line-height: 1; border-right:1px solid #d6d6d6; padding:12px 10px 12px 0px;}
.popup_call .rt .bx .inp {height:56px;}
.popup_call .rt .bx .loctx {font-size: 16px; min-height:56px; padding:16px 0px 0px 5px;}
.popup_call .rt .bx select {font-family: 'Montserrat', sans-serif; font-size: 16px; height:56px; border:none; width: 100%; outline: none;}
.popup_call .rt button {font-size: 16px; width:100%; background-color: #7D65AC; color:#ffffff; font-family: 'Montserrat', sans-serif; outline: none; border: none; height: 50px; border-radius: 30px; font-weight: 600; line-height: 1; text-align: center; cursor: pointer;}
.popup_call .rt .captcha {display: flex;}
.popup_call .rt .captcha .bx {width:100%; padding-left:0px;}
.popup_call .rt .captcha .pc {padding:0px 20px; margin-top:-12px;}
.popup_call .rt .captcha .lbl {width:100%; height:60px; background-color: #CFCFCF; border-radius: 10px; display: flex; align-items: center; justify-content: center; font-size: 18px; font-weight: 700; line-height: 1;}
.popup_call .rt .agree {position: relative; padding-left:50px; margin:0px auto 30px 0px; max-width: 600px;}
.popup_call .rt .agree .l {position: absolute; top: 0px; left: 0px;}
.popup_call .rt .agree .r {font-size: 14px; font-weight: 400; line-height: 1.5; color: #3b3b3b;}
.popup_call textarea {height: 160px !important; resize: none;}
.popup_call .cl {position:absolute; top:0px; right:0px; padding:20px; cursor: pointer; z-index: 1;}
.popup_call .con1{ text-align: center; font-size:28px; line-height: 1.2; color:#7D65AC; font-weight: 700; margin-bottom: 10px;}
.popup_call .con2{ text-align: center; font-size:18px; line-height: 1.2; color:#3B3B3B; font-weight: 500; margin-bottom: 50px; position: relative;}
.popup_call .con2:after {content: ''; position: absolute; bottom: -20px; left: 50%; transform: translateX(-50%); width: 115px; height: 2px; background-color: #000000;}
.popup_call .con3{ text-align: center; font-size:20px; line-height: 1.2; font-weight: 500; margin-bottom: 20px; text-transform: uppercase;}

.popup_call .pgCareer .sec1 .rt {width: 100%; padding:0px;}
.popup_call .pgCareer .sec1 .rt .bx .t1 {text-align: left;}
.popup_call .pgCareer .sec1 .rt .bx .t2 .lc {text-align: left;}
.popup_call .pgCareer .sec1 .rt .bx .t2 .dt {text-align: left;}
.popup_call .cr_tbl {display:grid; grid-template-columns:repeat(2,minmax(0,1fr)); gap:0px; margin-bottom:40px; width: 100%; max-width: 500px; text-align: left;}
.popup_call .cr_tbl .t {width:100%; border: 1px solid #d8d8d8; padding: 6px 20px; margin-bottom:-1px;}
.popup_call .cr_tbl .t:nth-child(odd) {font-weight: 500;}
.popup_call .cr_tbl .t:nth-child(even) {margin-left:-1px; font-size:14px;}
.popup_call .btext .btn_1 {width: 170px; margin:0px auto 40px auto;}
.popup_call .btext .t1 {font-size:16px; line-height: 1.2; text-align: center; margin-bottom: 20px; font-weight: 500; text-transform: uppercase;}
.popup_call .btext .t2 {font-size:26px; line-height: 1.2; text-align: center; margin-bottom: 20px;}



.popup_call.loc .lt {background-image: url(/public/images/pop_pic2.jpg);}

.popup_call.crapply .lt {background-image: url(/public/images/pop_pic3.jpg);}
.popup_call.crapply .lt {width: 32%;}
.popup_call.crapply .rt {width: 68%;}
.popup_call.crapply .inputfile {padding-left:10px; font-size: 16px; position: relative;}
.popup_call.crapply .inputfile .lk {display: flex; height: 56px; align-items: center;}
.popup_call.crapply .inputfile .lk .lbl {margin: 0px;}
.popup_call.crapply .inputfile .lk .btn_1 {width: 180px; margin: 0px 0px 0px auto; border-radius: 6px; height: 40px; padding-top: 11px;}
.popup_call.crapply .rws {display: flex; gap: 16px;}
.popup_call.crapply .clm {width: 100%;}

.popup_call.viewdetail .lt {background-image: none; width: 40%; text-align: center; padding:40px 20px;}
.popup_call.viewdetail .lt .pc {margin-bottom:20px;}
.popup_call.viewdetail .lt .pc img {display: inline-block; width: 100%; max-width: 325px;}
.popup_call.viewdetail .lt .t1 {font-size: 36px; font-weight: 700; line-height: 1; margin-bottom: 10px;}
.popup_call.viewdetail .lt .t2 {font-size: 20px; font-weight: 500; line-height: 1.2; margin-bottom: 10px;}
.popup_call.viewdetail .lt .t3 {font-size: 16px; font-weight: 500; line-height: 1.2; margin-bottom: 6px;}
.popup_call.viewdetail .lt .t4 {display: inline-block; font-size: 14px; font-weight: 500; line-height: 1.2; text-align: center; margin-bottom: 20px; background: #C7E0F2; border-radius: 26px; padding: 16px 30px;}
.popup_call.viewdetail .lt .btn_1 {max-width: 250px; margin: 0px auto;}
.popup_call.viewdetail .rt {width: 60%; padding-left:0px; font-size: 16px; font-weight: 500; line-height: 1.5; color:#3B3B3B; }
.popup_call.viewdetail .rt .t1 {font-size: 24px; font-weight: 700; line-height: 1.2; margin-bottom: 20px; margin-top:40px; color:#000000;}
.popup_call.viewdetail .rt .t2 {font-size: 22px; font-weight: 600; line-height: 1.2; margin-bottom: 10px;}
.popup_call.viewdetail .rt p {margin-bottom: 20px;}
.popup_call.viewdetail .rt ul {margin-bottom: 20px; list-style: none;}
.popup_call.viewdetail .rt ul li {padding-left:24px; position: relative; padding-bottom: 10px;}
.popup_call.viewdetail .rt ul li:before {content: ''; position: absolute; top: 6px; left: 0px; width: 10px; height: 10px; background-color: #955EDA; border-radius: 50%;}
.popup_call.viewdetail .rt .ul_1 li {padding-left: 34px;}
.popup_call.viewdetail .rt .ul_1 li:before {content: ''; position: absolute; top: -1px; left: 0px; width: 24px; height: 24px; background: url(/public/images/ic_tick.svg) no-repeat 0px 0px; background-size: 24px; border-radius: 50%;}
.popup_call.viewdetail .rt .no_m_top {margin-top: 0px !important;}


.btext {font-size: 16px; font-weight: 400; line-height: 1.5;}
.btext h1 {font-size: 20px; font-weight: 600; line-height: 1; margin: 30px 0px 20px 0px;}
.btext p {margin-bottom: 24px;}
.btext ul li {position: relative; padding-left: 10px; padding-bottom: 8px;}
.btext ul li:after {content: ''; position: absolute; top: 10px; left: 0px; width: 2px; height: 2px; background-color: #000000; border-radius: 50%;}


header {position: fixed; top:0px; left:0px; width:100%; height:120px; z-index: 99999; background-color: rgba(255,255,255,0.8); transition:0.5s;}
header .container {max-width: 1520px; padding: 0px 20px;}
footer .container {max-width: 1520px; padding: 0px 20px;}
header .lt {float:left; margin-top:20px; width:240px; transition:0.5s;}
header .lt img {width:100%;}
header .md {position:absolute; left:50%; top:50%; transform: translate(-50%,-50%); font-size: 18px; font-weight: 500; line-height: 22px;}
header .md a {color:#000000;}
header .md .mn {float:left; position:relative;}
header .md .mn .lk { padding:20px 20px; display: block;}
header .md .mn .mn_lk {position: absolute; left: 20px; top: 50px; width: 100%; background: #f1f1f1; padding: 0px; display: block; min-width: 230px; box-shadow: 2px 2px 6px rgba(0,0,0,0.1); display: none; border-radius: 10px; overflow: hidden;}
header .md .mn .mn_lk a {border-bottom: 1px solid #dacceb; display: block; padding: 10px 10px; font-size: 14px; font-weight: 500; transition: 0.5s;}
header .md .mn .mn_lk a:last-child {border:none;}
header .md .mn.has_menu:after {content: ""; width: 15px; height:15px; background:url(/public/images/ar_down.png) no-repeat center center; position: absolute; top: 24px; right: 0px;}
header .rt {float:right; display: flex; gap:18px; margin-top:38px; transition:0.5s;}
header .rt .lk1 {width: 41px; height:41px; background: url(/public/images/ic_wp.svg) no-repeat 3px 3px #32B311; border-radius: 20.5px; transition:0.5s;}
header .rt .lk2 {height:41px; border-radius: 20.5px; color:#ffffff; font-size: 16px; font-style: italic; font-weight: 600; line-height: 20px; padding: 10px 14px 0px 50px; background: url(/public/images/ic_phone2.svg) no-repeat 6px 3px #7D65AC; transition:0.5s;}
header .rt .btn_menu {font-size: 18px; font-weight: 600; line-height: 1; letter-spacing: 1px; color:#7D65AC; background: url(/public/images/btn_menu.svg) no-repeat center right; padding: 10px 40px 0px 0px; cursor:pointer;}
header .rt .btn_menu.active {background: url(/public/images/btn_menu_close.svg) no-repeat top 8px right 0px;}
header .blackbg {position: fixed; top:0px; right:0px; width:0%; height:100%; background-color: rgba(0,0,0,0.5); -webkit-backdrop-filter: blur(5px); -o-backdrop-filter: blur(5px); -moz-backdrop-filter: blur(5px); backdrop-filter: blur(5px); z-index: 1; transition: 0.5s;}


.hdr_mn {position: fixed; top: 0px; right: -360px; width:360px; height:100vh; overflow-y: scroll; overflow-x: hidden; z-index:999; transition: 0.5s;}
.hdr_mn .container {background: #7d65ac; width: 100%; padding-top: 60px; min-height: 100vh; display: flex; flex-direction: column; justify-content: space-between;}
.hdr_mn .dv_tp {width: 100%;}
.hdr_mn .dv {width: 100%; text-align: center; color:#ffffff; padding: 20px 20px 10px 20px; background: rgba(255, 255, 255, 0.1); border-radius: 10px; margin-top: 20px; margin-bottom: 20px;}
.hdr_mn .dv .ttl {font-weight: 700; font-size: 16px; border-bottom: 1px solid #a78dd7; padding-bottom:10px; margin-bottom: 10px;}
.hdr_mn .dv .lk1 {border: none; padding: 6px 0px;}
.hdr_mn .dv .lk2 {border: none; padding: 6px 0px;}
.hdr_mn .dv .ics {display: flex; align-items: center; justify-content: center; gap: 10px;}
.hdr_mn .dv .ics a {border: none; padding: 6px 0px;}
.hdr_mn a {display: block; border-top: 1px solid #9981c6; color:#ffffff; font-size: 16px; font-weight: 400; letter-spacing: 1px; line-height: 1.2; padding:14px 0px; transition: 0.5s;}
.hdr_mn .lk.bs {display: block; cursor: pointer; border-top: 1px solid #9981c6; color:#ffffff; font-size: 16px; font-weight: 400; letter-spacing: 1px; line-height: 1.2; padding:14px 0px; transition: 0.5s;}
.hdr_mn.active {right:0px;  box-shadow: 0px 0px 20px rgba(0,0,0,0.4);}
.hdr_mn .mn_lk {display: none;}
.hdr_mn .md_new .mn {position: relative;}
.hdr_mn .md_new .mn.has_menu:after {content: ""; width: 15px; height:15px; background:url(/public/images/ar_down.png) no-repeat center center; position: absolute; top: 16px; right: 0px; cursor: pointer;}
.hdr_mn .md_new .mn.active .mn_lk{display: block; padding-bottom: 20px;}
.hdr_mn .md_new .mn.active .mn_lk a  {font-size: 14px; border:none; padding:8px 0px 8px 20px;}
.hdr_mn .md_new .mn.active.has_menu:after {background:url(/public/images/ar_down_active.png) no-repeat center center;}
header .blackbg.active {width:100%;}

.scroll header {background-color: #ffffff; box-shadow: 0px 0px 63px rgba(0,0,0,0.1); height:70px;}
.scroll header .lt {width:140px; margin-top:10px;}
.scroll header .rt {margin-top: 14px;}

.mb_menu .close_menu {margin: 0px 0px 0px auto; padding: 22px 20px; cursor: pointer; position: absolute; top: 0px; right: 20px; width: 56px; margin-right: -80px; z-index: 11111; background-color: rgb(125 101 172); transition: 0.5s;}
.mb_menu .close_menu.active {margin-right:0px;}


.banner {margin-bottom:60px;}
.banner .item {max-height: calc(100vh - 100px);}
.banner .item .tx {position: absolute; left: 100px; top: 50%; transform: translateY(-50%); color: #ffffff; font-size: 60px; font-weight: 700; line-height: 1; max-width: 800px;}
.banner .item .pc {background-color: #000000;}
.banner .item .pc img {opacity:0.9;}
.banner .owl-theme .owl-dots {display: block; position: absolute; bottom: 30px; left: 50%; transform: translateX(-50%);}
.banner .owl-dots .owl-dot span {height:10px !important; width:18px !important; border-radius: 6px !important; background-color: #ffffff !important; border:none !important; margin:0px 2px !important;}
.banner .owl-dots .owl-dot.active span {background-color: #ffffff; width:75px !important;}
.banner-img.desk{display:block; padding-left:0 !important}
.banner-img.mob{display:none;}

.topbanner {position:relative; max-height: calc(100vh - 100px);  background-repeat: no-repeat; background-position: center top; background-size: cover; overflow: hidden;}
.topbanner .pc {position:relative; height:100%;}
.topbanner .pc img {width: 100%;}
.topbanner .container {position: absolute; width: 100%; height: 100%; top:0px; left:0px;}
.topbanner .tx {position: absolute; left: 100px; bottom: 30px; font-size: 40px; max-width: 500px; font-weight: 700; line-height: 1; color: #ffffff;}
.topbanner h1 {position: absolute; left: 100px; bottom: 30px; font-size: 40px; max-width: 500px; font-weight: 700; line-height: 1; color: #ffffff;}


.breadcrumbs {padding:30px 0px;}
.breadcrumbs .dv {position: relative; float:left; font-size: 14px; font-weight: 400; line-height: 1.2; padding-right:20px;}
.breadcrumbs .dv:after {content: ""; width: 15px; height:15px; background:url(/public/images/ar_right.svg) no-repeat center center; position: absolute; top: 1px; right: 3px;}
.breadcrumbs .dv a {color:#000000;}
.breadcrumbs .dv:last-child {color: #955EDA;}
.breadcrumbs .dv:last-child:after {display: none;}

.topnav {font-size:15px; line-height: 1.1; background-color: #F2F2F2;}
.topnav .mb_ttl {font-size: 18px; font-weight: 600; line-height: 1.2; padding:14px 10px 14px 20px; display: none; background: url(/public/images/ic_dots.svg) no-repeat right 20px top 14px; background-size: 5px;}
.topnav .mb_ttl .active{background-image: url(/public/images/ic_cross.svg); background-size: 20px;}
.topnav .container {display: flex; justify-content: space-between; padding-top:10px; padding-bottom: 10px; gap:10px; align-items: center;}
.topnav .container .lk {color: #000000; font-weight: 400; background-color: transparent; padding: 9px 20px 7px 20px; border-radius: 20px; text-align: center; transition: 0.5s; cursor: pointer;}
.topnav .container .lk.active {color: #ffffff;  background-color: #7d65ac;}
.topnav.scroll {position: fixed; width:100%; top: 70px; left:0px; z-index: 99; box-shadow: 0px 0px 8px rgba(0,0,0,0.2);}

/* CSS Changes on 20 apr  */

.topnav2 {font-size:15px; line-height: 1.1; background-color: #F2F2F2; transition: 0.5s;}
.topnav2 .mb_ttl {font-size: 18px; font-weight: 600; line-height: 1.2; padding:14px 10px 14px 20px; display: none; background: url(/public/images/ic_dots.svg) no-repeat right 20px top 14px; background-size: 5px;}
.topnav2 .mb_ttl.active{background-image: url(/public/images/ic_cross.svg); background-size: 20px;}
.topnav2 .container {display: flex; justify-content: space-between; padding-top:10px; padding-bottom: 10px; gap:10px; align-items: center;}
.topnav2 .container .lk {color: #000000; font-weight: 400; background-color: transparent; padding: 9px 20px 7px 20px; border-radius: 20px; text-align: center; transition: 0.5s; cursor: pointer; width: 100%;}
.topnav2 .container .lk.active {color: #ffffff;  background-color: #7d65ac;}
.topnav2 {position: fixed; width:100%; top: 100px; left:0px; z-index: 99; box-shadow: 0px 0px 8px rgba(0,0,0,0.2);}
.topnav2.scroll {top: 70px;}
.scroll .topnav2 {top: 70px;}


/*  */


.hp_sec1 {margin-bottom:80px;}
.hp_sec2 {margin-bottom:80px;}
.hp_sec3 {margin-bottom:80px;}
.hp_sec4 {margin-bottom:80px;}
.hp_sec5 {margin-bottom:80px;}

.section {margin-bottom:80px;}


.hp_sec1 .item .t1 {font-size: 22px; font-weight: 600; line-height: 1.2; text-align: center; margin-bottom:20px;}
.hp_sec1 .item .cnt {display: flex; gap:40px;}
.hp_sec1 .item .dv .pc {margin-bottom: 20px; border-radius: 20px; overflow: hidden;}
.hp_sec1 .item .dv .pc img {transform-origin: center; transition: 0.5s;}
.hp_sec1 .item .dv .t2 {font-size: 18px; font-weight: 600; line-height: 1.2; text-align: center;}
.hp_sec1 .pgProduct .sec7 {background-color: transparent;}


.hp_sec2 .item {position:relative; border-radius: 20px; overflow: hidden;}
.hp_sec2 .pc img {transform-origin: center; transition: 0.5s;}
.hp_sec2 .grad {position:absolute; bottom:0px; left:0px; width:100%; opacity:0; transition: 0.5s;}
.hp_sec2 .grad img {width:100%;}
.hp_sec2 .tx {font-size: 28px; font-weight: 500; line-height: 32px; position: absolute; left:calc(-100% - 80px); bottom: 40px; color: #fff; padding: 0px 40px; transition: 0.5s ; width:100%}

.hp_sec3 .pc {overflow:hidden; border-radius: 20px;}
.hp_sec3 .pc img {transform-origin: center; transition: 0.5s;}
.hp_sec3 .grad {position: absolute; background: rgba(142, 90, 207, 0.60); top: 50%; left: -100%; width: 100%; height:250px; transform: translateY(-50%); transition: 0.5s cubic-bezier(.17,.67,.15,1.26);}
.hp_sec3 .tx {position: absolute; top:50%; left:0px; width:100%; padding:0px 20px; transform: translateY(-50%);}
.hp_sec3 .tx .t1 {position:relative; font-size: 32px; font-weight: 700; line-height: 1; text-align: center; color:#ffffff; margin-bottom:20px;}
.hp_sec3 .tx .t1:after {position:absolute; content:""; width:130px; height:2px; background-color: #ffffff; left:50%; transform:translateX(-50%); bottom:-12px;}
.hp_sec3 .tx .t2 {font-size: 18px; font-weight: 400; line-height: 1.4; text-align: center; color:#ffffff; margin-bottom: -60px; transition: 0.5s;}
.hp_sec3 .tx .lk {display:block; width:134px; height:40px; background-color: #ffffff; color:#000000; font-size: 14px; font-weight: 500; border-radius: 6px; text-align: center; padding-top:9px; margin:20px auto 0px auto; opacity:0; transition: 0.5s;}
.hp_sec3 .item {position:relative; overflow: hidden;}


.hp_sec4 {position:relative; padding-top:70px;}
.hp_sec4:before {position: absolute; content:""; width:100%; height:560px; background-color: #f2f2f2; left:0px; top:0px;}
.hp_sec4 .container {z-index: 9; position: relative;}
.hp_sec4 .item {position:relative;}
.hp_sec4 .pc {border-bottom: 10px solid #7d65ac; overflow:hidden; border-radius: 20px 20px 0px 0px;}
.hp_sec4 .pc img {transform-origin: center; transition: 0.5s;}
.hp_sec4 .grad {position:absolute; bottom:10px; left:0px; width:100%;}
.hp_sec4 .grad img {width:100%;}
.hp_sec4 .play {position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); cursor: pointer;}
.hp_sec4 .play img {z-index: 9; position: relative;}
.hp_sec4 .play:before {position: absolute; content:""; width:76px; height:76px; margin:-10px 0px 0px -10px; background-color: #7d65ac; border-radius: 50%; transform-origin: center; transform: scale(0); transition: 0.5s cubic-bezier(.17,.67,.15,1.26); opacity: 0;}
.hp_sec4 .tx {font-size: 16px; font-weight: 500; line-height: 1.2; position: absolute; bottom: 30px; color: #fff; padding: 0px 20px;}
.hp_sec4 .tx a {font-weight: 500; color: #7D65AC; background: url(/public/images/ic_ar.jpg) no-repeat top 10px right 0px; padding-right: 20px;}

.hp_sec5 .item {border:1px solid #c9c9c9; padding:16px; border-radius: 20px; transition: 0.5s;}
.hp_sec5 .pc {margin-bottom:20px; overflow:hidden; border-radius: 10px;}
.hp_sec5 .pc img {transform-origin: center; transition: 0.5s;}
.hp_sec5 .tx {font-size: 16px; font-weight: 600; line-height: 1.3; padding-bottom:10px;}
.hp_sec5 .tx a {font-weight: 500; color: #7D65AC; background: url(/public/images/ic_ar.jpg) no-repeat top 7px right 0px; padding-right: 20px;}
.hp_sec5 .owl-carousel {margin-bottom: 40px;}
.hp_sec5 .lk {width: 185px; margin:0px auto;}

.pgCon .hp_sec3 .pc {position:relative;}
.pgCon .hp_sec3 .pc img {width: 100%;}
.pgCon .hp_sec3 .pc .sh {position:absolute; top:0px; left:0px; width:100%; height: 100%; background: rgba(0, 0, 0, 0.3);}
.pgCon .hp_sec3 .tx .t2 {max-width: 440px;}
.pgCon .grd {display:grid; grid-template-columns:repeat(2,minmax(0,1fr)); gap:30px; margin-top:30px;}


.pgAbout .title {text-align: left; margin-bottom: 30px;}
.pgAbout .sec1 {background: #F2F2F2; padding: 60px 0px; margin-bottom: 50px;}
.pgAbout .sec1 .tx {font-size: 18px; font-weight: 500; line-height: 1.5; color:#3B3B3B;}
.pgAbout .sec1 .tx p {margin-bottom:20px;}
.pgAbout .sec2 .item {border-radius: 10px; overflow: hidden;}
.pgAbout .sec2 .item img {width: 100%; transition:0.5s; transform-origin: center; height: 100%;}
.pgAbout .sec3 .t1 {width:100%; font-size: 26px; font-weight: 700; line-height: 1.2; color: #7D65AC; margin-bottom: 14px;}
.pgAbout .sec3 .t2 {width: 100%; font-size: 38px; font-weight: 700; line-height: 42px; margin-bottom: 30px;}
.pgAbout .sec3 .t3 {width: 100%; font-size: 18px; font-weight: 500; line-height: 1.5; color: #3B3B3B;}
.pgAbout .sec3 .t3 p {margin-bottom:30px;}
.pgAbout .sec3 .container {display:grid;grid-template-columns:repeat(2,minmax(0,1fr));gap:0px; }
.pgAbout .sec3 .lt .pc {overflow: hidden; border-radius: 20px;}
.pgAbout .sec3 .lt img {width: 100%; transition: 0.5s; transform-origin: center;}
.pgAbout .sec3 .rt {padding-left:80px; display: flex; flex-direction: column; justify-content: center;}
.pgAbout .sec3.rev .lt {order: 1;}
.pgAbout .sec3.rev .rt {padding-left:0px; padding-right:80px;}
.pgAbout .sec4 {position:relative; background-color: #f2f2f2; padding: 60px 0px 0px 0px;}
.pgAbout .sec4:after {position: absolute; content: ""; width: 100%; height: 90px; left: 0px; bottom: 0px; background: #ffffff;}
.pgAbout .sec4 .tx {font-size: 18px; font-weight: 500; line-height: 1.5; color: #3B3B3B; margin-bottom:60px;}
.pgAbout .sec4 .tx p {margin-bottom: 30px;}
.pgAbout .sec4 .item {position: relative; overflow: hidden; border-radius: 20px;}
.pgAbout .sec4 .item img {transition: 0.5s; transform-origin: center;}
.pgAbout .sec4 .item .dv {position:absolute; top:0px; left:0px; width:100%; height:100%; background: rgba(0,0,0,0.3); color:#ffffff; padding:0px 20px; text-align: center; display: flex; flex-direction: column; justify-content: center;}
.pgAbout .sec4 .item .dv .t1 {font-size: 38px; font-weight: 900; line-height: 1; margin-bottom:10px;}
.pgAbout .sec4 .item .dv .t2 {font-size: 20px; font-weight: 600; line-height: 1.3; max-width: 200px;}
.pgAbout .sec5 .item {position: relative; overflow: hidden; border-radius: 20px;}
.pgAbout .sec5 .item img{transition: 0.5s; transform-origin: center;}
.pgAbout .sec5 .item img.active {transform: scale(1.1);}
.pgAbout .sec5 .item .dv {position: absolute; left:0px; bottom:0%; color:#ffffff; padding:30px 40px;}
.pgAbout .sec5 .item .dv .bg {position: absolute; left:0px; top:0%; width: 100%; height: 100%; background: #7D65AC; content: '';}
.pgAbout .sec5 .item .dv .t1 {position: relative; z-index: 9; font-size: 24px; font-weight: 700; line-height: 1.1; margin-bottom:0px; transition:0.5s;}
.pgAbout .sec5 .item .dv .t2 {position: relative; z-index: 9; font-size: 16px; font-weight: 400; line-height: 1.5; height:1px; overflow: hidden;}


.pgLocation .sec1 .ct {background: #F2F2F2; border-radius: 15px; padding:40px;}
.pgLocation .sec1 .title {text-align: left;}
.pgLocation .sec1 .title h3 {font-size: 22px; font-weight: 500; line-height: 1.2; margin:-10px 0px 20px 0px;}
.pgLocation .sec1 .grd {font-size: 18px; font-weight: 500; line-height: 1.2;}
.pgLocation .sec1 .grd .dv {position:relative; float:left;}
.pgLocation .sec1 .grd .dv .lbl {margin-bottom:10px;}
.pgLocation .sec1 .grd .dv .bx {background-color: #ffffff; height:56px; border-radius: 28px; overflow:hidden; display: flex;}
.pgLocation .sec1 .grd .dv .bx .l {position:relative; padding: 17px 0px 0px 20px; width:230px; overflow: hidden;}
.pgLocation .sec1 .grd .dv .bx .l .tx {text-align: left; white-space: nowrap; font-size: 16px;}
.pgLocation .sec1 .grd .dv .bx .l::after {content: ''; position: absolute; right: 0px; top: 10px; width: 1px; height: 36px; background: #A7A7A7;}
.pgLocation .sec1 .grd .dv .bx .l::before {content: ''; position: absolute; right: 0px; top: 18px; width: 20px; height: 20px; background: url(/public/images/ic_dd.svg) no-repeat center center #fff; padding-right: 10px;}
.pgLocation .sec1 .grd .dv .bx input[type=text] {font-size: 18px; height: 100%; width: 380px;}
.pgLocation .sec1 .grd .dv .bx .r {position: relative; padding-left:45px;}
.pgLocation .sec1 .grd .dv .bx .r::after {content: ''; position:absolute; left:12px; top:12px; width:32px; height:32px; background: url(/public/images/ic_search.svg) no-repeat center center;}
.pgLocation .sec1 .grd .dv .btn_1 {width: 170px; margin-top: 35px;}

.pgLocation .sec1 .grd .dv:nth-child(1) {float:none; border-bottom: 1px solid #ccc; padding-bottom:10px; margin-bottom: 20px;}
.pgLocation .sec1 .grd .dv:nth-child(1) .tx {font-size: 18px; font-weight: 600; line-height: 1.2; background:url(/public/images/ic_location.svg) no-repeat left center; cursor:pointer; padding:14px 10px 14px 40px; margin-top:-10px;}
.pgLocation .sec1 .grd .dv:nth-child(2) {clear: both;}
.pgLocation .sec1 .grd .dv:nth-child(3) {font-weight:700; padding:50px 20px 0px 20px;}
.pgLocation .sec1 .grd .dv:nth-child(5) {margin-left:20px;}

.pgLocation .sec2 .title h3 {font-size: 20px; font-weight: 600; line-height: 1.5; color: #955EDA;}
.pgLocation .sec2 .grd {display:grid;grid-template-columns:repeat(3,minmax(0,1fr));gap:35px;}
.pgLocation .sec2 .item {border:1px solid #c9c9c9; padding:16px; border-radius: 20px; transition: 0.5s;}
.pgLocation .sec2 .pc {margin-bottom:20px; overflow:hidden; border-radius: 10px;}
.pgLocation .sec2 .pc img {transform-origin: center; transition: 0.5s; width: 100%;}
.pgLocation .sec2 .tx {font-size: 18px; font-weight: 600; line-height: 24px; padding-bottom:20px;}
.pgLocation .sec2 .li {font-size: 16px; font-weight: 600; line-height: 1.5; padding:0px 0px 20px 50px; background-repeat: no-repeat; background-position: 10px 4px;}
.pgLocation .sec2 .minheight {min-height: 190px;}
.pgLocation .sec2 .li.ic1 {background-image: url(/public/images/ic1.svg);}
.pgLocation .sec2 .li.ic2 {background-image: url(/public/images/ic2.svg);}
.pgLocation .sec2 .lks {display: flex; gap:20px;}
.pgLocation .sec2 .lks a:nth-child(1) {background: transparent;border: 1px solid #000; color: #000;}
.pgLocation .sec2 .lks a {width:100%;}
.pgLocation .sec2 .lks .btn_1 {width:100%; cursor: pointer;}

.pgLocation .sec2 .lks a:hover:nth-child(1) {border: 1px solid #7D65AC; color: #7D65AC;}

.srch_menu {z-index:9; width: 100%; top:80px; left: 0px; padding: 10px; background-color: #fff; border-radius: 10px; box-shadow:0px 0px 10px rgba(0,0,0,0.2); position: absolute;}
.srch_menu input[type=text] {font-family: "Montserrat", sans-serif !important;}
.srch_menu .pcity label {font-weight: 600; margin-bottom: 10px; margin-top:20px; display: block;}
.srch_menu .pcity ul li {float:left; margin:0px 10px 10px 0px; background-color: #7D65AC; color:#ffffff; padding:10px 16px; border-radius: 50px;}
.srch_menu .sc-fqkvVR.jezLLd {background-color: #f2f2f2;}
.srch_menu .sc-gEvEer.hykAQO {width:100%;}


.pgMedia .sec2 .grd {display:grid;grid-template-columns:repeat(3,minmax(0,1fr));gap:30px;}
.pgMedia .sec2 .item {border:1px solid #c9c9c9; padding:16px; border-radius: 20px; transition: 0.5s;}
.pgMedia .sec2 .dv {position: relative; overflow: hidden;  border-radius: 10px; margin-bottom: 20px;}
.pgMedia .sec2 .dv:after {content: ''; position: absolute; left:0; top:0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.2);}
.pgMedia .sec2 .play {position:absolute; left:50%; top:50%; width:60px; transform:translate(-50%, -50%); z-index: 1;}
.pgMedia .sec2 .play img {width:100%;}
.pgMedia .sec2 .pc img {transform-origin: center; transition: 0.5s; width: 100%;}
.pgMedia .sec2 .tx {font-size: 16px; font-weight: 600; line-height: 24px; padding-bottom:14px;}
.pgMedia .sec2 .tx2 {font-size: 14px; font-weight: 500; line-height: 1.5; background-repeat: no-repeat; background-position: 10px 4px; padding-bottom:20px;}
.pgMedia .sec2 .tx3 {position: absolute; top: 50%; left: 0px; width: 100%; padding: 0px 20px; transform: translateY(-50%); color: #fff; font-size: 26px; font-weight: 600; line-height: 1.2; text-align: center; z-index: 1;}
.pgMedia .sec2 .lk {color:#7D65AC; font-size: 16px; font-weight: 500; line-height: 1; text-decoration: underline; transition: 0.5s;}
.pgMedia.pgBlog .sec2 .dv {margin-bottom: 0px;}

/* // CSS Changes 21 apr // */
.pgMedia .sec3 .grd {display:grid;grid-template-columns:repeat(3,minmax(0,1fr));gap:30px;}
.pgMedia .sec3 .item {border:1px solid #c9c9c9; padding:16px; border-radius: 20px; transition: 0.5s;}
.pgMedia .sec3 .dv {position: relative; overflow: hidden;  border-radius: 10px; margin-bottom: 20px; cursor: pointer;}
.pgMedia .sec3 .dv:after {content: ''; position: absolute; left:0; top:0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.2);}
.pgMedia .sec3 .play {position:absolute; left:50%; top:50%; width:60px; transform:translate(-50%, -50%); z-index: 1;}
.pgMedia .sec3 .play img {width:100%;}
.pgMedia .sec3 .pc img {transform-origin: center; transition: 0.5s; width: 100%;}
.pgMedia .sec3 .tx {font-size: 20px; font-weight: 600; line-height: 24px; padding-bottom:14px;}
.pgMedia .sec3 .tx2 {font-size: 16px; font-weight: 500; line-height: 1.5; background-repeat: no-repeat; background-position: 10px 4px; color:#7D65AC; font-weight: 700;}
.pgMedia .sec3 .tx3 {font-size: 16px; font-weight: 500; line-height: 1.5; background-repeat: no-repeat; background-position: 10px 4px;}
.pgMedia .sec3 .lks {font-size: 0px; line-height: 1.2; text-align: center; padding:40px 0px 30px 0px;}
.pgMedia .sec3 .lks a {display: inline-block; font-size: 14px; line-height: 1.2; font-weight: 500; padding:10px 20px; transition: 0.5s; border-radius: 20px; color: #000000; background-color: #f2f2f2; margin:0px 5px 10px 5px;}
.pgMedia .sec3 .lks a:hover {background-color: #7d65ac; color: #ffffff;}
.pgMedia .sec3 .lks a.active {background-color: #7d65ac; color: #ffffff;}

/*  */

.pgBlog .sec1 .title h3 {font-size: 20px; font-weight: 600; line-height: 1.5; color: #955EDA;}
.pgBlog .sec1 .grd {display:grid;grid-template-columns:repeat(3,minmax(0,1fr));gap:30px; padding-top:20px;}
.pgBlog .sec1 .item {border:1px solid #c9c9c9; padding:16px 16px 20px 16px; border-radius: 20px; transition: 0.5s;}
.pgBlog .sec1 .dv {position: relative; overflow: hidden;  border-radius: 10px; margin-bottom: 20px;}
.pgBlog .sec1 .tg {float:left; font-size: 14px; font-weight: 400; line-height: 1.2; background: #7D65AC; color:#ffffff; padding:7px 20px; border-radius: 20px; margin-bottom: 16px;}
.pgBlog .sec1 .pc img {transform-origin: center; transition: 0.5s; width: 100%;}
.pgBlog .sec1 .tx {font-size: 16px; font-weight: 600; line-height: 24px; padding-bottom:20px;}
.pgBlog .sec1 .tx2 {position: relative; font-size: 14px; font-weight: 500; line-height: 1.4; padding-left:60px; margin-bottom: 20px;}
.pgBlog .sec1 .tx2 img {width: 50px; height: 50px; border-radius: 50%; position:absolute; left:0; top:0;}
.pgBlog .sec1 .tx2 a {font-size: 14px; color: #7D65AC; text-decoration: underline;}
.pgBlog .sec1 .tx3 {position: relative; font-size: 16px; font-weight: 500; line-height: 1.4; padding-left:30px; background: url(/public/images/ic_calender.svg) no-repeat 0px 1px; color:#3b3b3b; margin-bottom: 20px;}
.pgBlog .sec1 .lk {color:#000000; font-size: 16px; font-weight: 500; line-height: 1; text-decoration: underline; transition: 0.5s;}
.pgBlog .sec1 .title {text-align: left;}


.pgBlog.pgDetail .btext {font-size: 18px; font-weight: 400; line-height: 1.5; color:#3b3b3b; margin-bottom: 40px;}
.pgBlog.pgDetail .btext p {margin-bottom: 20px;}
.pgBlog.pgDetail .btext a {color:#3b3b3b; text-decoration: underline;}
.pgBlog.pgDetail .btext b {font-weight: 700;}

.pgBlog.pgDetail .sec0 .lft .tp_ct {display: flex; font-size: 16px; font-weight: 500; align-items: center; margin-bottom: 30px;}
.pgBlog.pgDetail .sec0 .lft .tp_ct .l {margin:0px; position: relative; padding-left: 60px;}
.pgBlog.pgDetail .sec0 .lft .tp_ct .l img {position: absolute; top: -2px; left: 0px;}
.pgBlog.pgDetail .sec0 .lft .tp_ct .l a {font-weight: 600; color:#7D65AC;}
.pgBlog.pgDetail .sec0 .lft .tp_ct .r {margin-right:0px; background: url(/public/images/ic_calender.svg) no-repeat top 1px left 0px; padding-left: 28px;}
.pgBlog.pgDetail .sec0 .lft .smo {background-color: #7D65AC; display: flex; align-items: center; padding:10px; border-radius: 50px; float:left; margin-bottom:20px;}
.pgBlog.pgDetail .sec0 .lft .smo a {padding:0px 6px;}
.pgBlog.pgDetail .sec0 .lft {float:left; width:70%;}
.pgBlog.pgDetail .sec0 .lft .pc {margin-bottom:16px;}
.pgBlog.pgDetail .sec0 .lft .pc img {width: 100%; border-radius: 10px;}
.pgBlog.pgDetail .sec0 .lft .tg {float:left; font-size: 14px; font-weight: 500; line-height: 1.2; background-color: #7D65AC; color:#ffffff; padding:6px 16px;margin-bottom:16px; border-radius: 30px; margin-right:10px;}
.pgBlog.pgDetail .sec0 .lft a.tg {text-decoration: none; background-color: #f2f2f2; transition: 0.5s; color: #000000;}
.pgBlog.pgDetail .sec0 .lft a.tg:hover {background-color: #7D65AC; color:#ffffff;}
.pgBlog.pgDetail .sec0 .lft .h1 {font-size: 26px;font-weight: 600;line-height: 1.2; margin-bottom: 20px;}
.pgBlog.pgDetail .sec0 .lft h1 {font-size: 26px;font-weight: 600;line-height: 1.2; margin-bottom: 20px;}

.pgBlog.pgDetail .sec0 .lft .ct {background: #F2F2F2; border-radius: 10px; padding:40px; margin-bottom: 40px;}
.pgBlog.pgDetail .sec0 .lft .ct .tx {font-size: 26px; font-weight: 500; line-height: 1; margin-bottom:14px;}
.pgBlog.pgDetail .sec0 .lft .ct a {clear:both; float:left; color:#3b3b3b; font-size: 18px; font-weight: 500; padding:4px 0px; text-decoration: none; transition:0.5s;}
.pgBlog.pgDetail .sec0 .btext .h1 {font-size: 26px; font-weight: 600; line-height: 1.2; color:#000000; margin-bottom: 26px;}
.pgBlog.pgDetail .sec0 .btext .h1 span {color:#7D65AC;}
.pgBlog.pgDetail .sec0 .btext h2 {font-size: 20px; font-weight: 600; line-height: 1.2; color:#000000; margin-bottom: 26px;}
.pgBlog.pgDetail .sec0 .rgt {float:left; width:30%; padding-left:40px;}

.pgBlog.pgDetail .sec1 .item .tg {font-size: 12px;}
.pgBlog.pgDetail .sec1 .grd {gap:16px;}
.pgBlog.pgDetail .sec1 .item {padding:10px 10px 16px 10px; border-radius: 10px;}
.pgBlog.pgDetail .sec1 .dv {margin-bottom:0px;}
.pgBlog.pgDetail .sec1 .tx {font-size: 15px; line-height: 22px;}
.pgBlog.pgDetail .sec1 .tx2 {font-size: 12px; padding-left: 40px; margin-bottom: 10px;}
.pgBlog.pgDetail .sec1 .tx2 img {width: 30px; height: 30px;}
.pgBlog.pgDetail .sec1 .tx2 a {font-size: 12px;}
.pgBlog.pgDetail .sec1 .tx3 {font-size: 12px; background-size: 16px; padding-left: 20px; background-position-y: -1px;}
.pgBlog.pgDetail .popup_call {position: relative; background-color: #ffffff; overflow: unset; width:100%; padding:0px; border: 1px solid #B5B5B5; border-radius: 10px; padding:20px; z-index: unset;}
.pgBlog.pgDetail .popup_call .rt {width: 100%; padding:0px;}
.pgBlog.pgDetail .popup_call .rt .bx {padding-left:40px;}
.pgBlog.pgDetail .popup_call .rt .bx .inp {height: 46px;}
.pgBlog.pgDetail .popup_call .rt .bx .ic {height: 46px;width: 46px;}
.pgBlog.pgDetail .popup_call .rt .bx .ic img {width: 20px;}
.pgBlog.pgDetail .popup_call .rt .bx.ph {padding-left: 80px;}
.pgBlog.pgDetail .popup_call .rt .bx.ph .ic img {width: 14px;}
.pgBlog.pgDetail .popup_call .rt .bx .cc {position: absolute; top: 4px; left: 40px; font-size: 14px;}
.pgBlog.pgDetail .popup_call .rt .bx select {height:46px;}
.pgBlog.pgDetail .popup_call .rt .captcha .bx {padding-left:0px;}
.pgBlog.pgDetail .popup_call .rt .captcha .lbl {height:48px;}
.pgBlog.pgDetail .popup_call .rt .captcha .pc {padding: 0px 10px; margin-top: -16px; width: 40px;}
.pgBlog.pgDetail .lft .popup_call .rt button {max-width: 200px;}
.pgBlog.pgDetail .checkmark {height: 34px; width: 34px;}
.pgBlog.pgDetail .popup_call .rt .agree {padding-left: 46px;}
.pgBlog.pgDetail .frm_cnt .checkmark:after {left: 10px; top: 4px; }
.pgBlog.pgDetail .sec1 {margin-top:60px;}


.pgBlog.pgDr .title img {width:160px; height:160px; object-fit: cover; border-radius: 50%; margin:0px auto 20px auto;}
.pgBlog.pgDr .title h3 {font-size: 20px; margin-bottom: 20px;}
.pgBlog.pgDr .title h4 {font-size: 18px; font-weight:400;}

.pgBlog.pgLocDr .sec1 .ct {display: flex; margin-bottom:80px;}
.pgBlog.pgLocDr .sec1 .title {text-align: left;}
.pgBlog.pgLocDr .sec1 .lt {width: 45%;}
.pgBlog.pgLocDr .sec1 .lt .pc {width: 100%; border-radius: 20px; margin-bottom: 20px;}
.pgBlog.pgLocDr .sec1 .lt .lks {display: flex; gap:20px;}
.pgBlog.pgLocDr .sec1 .lt .lc {border:1px solid #000000; width:40%; min-height: 50px; border-radius: 25px; text-align: center; padding: 10px 10px 10px 0px; display: flex; align-items: center; justify-content: center; font-size: 16px; font-weight: 600;}
.pgBlog.pgLocDr .sec1 .lt .lc img {margin-left: 10px; margin-right: 10px;}
.pgBlog.pgLocDr .sec1 .lt .btn_1 {width:60%; height: auto; padding-bottom: 17px; min-height: 52px;}
.pgBlog.pgLocDr .sec1 .rt {width: 55%; padding-left:40px; font-size: 18px; font-weight: 400; line-height: 1.5; color:#3b3b3b}
.pgBlog.pgLocDr .sec1 .rt .t1 {font-size: 38px; font-weight: 600; line-height: 1; margin-bottom: 10px; color:#000000;}
.pgBlog.pgLocDr .sec1 .rt h1 {font-size: 38px; font-weight: 600; line-height: 1; margin-bottom: 10px; color:#000000;}
.pgBlog.pgLocDr .sec1 .rt .t2 {font-size: 18px; font-weight: 600; line-height: 1.2; margin-bottom: 20px; color: #7D65AC;}
.pgBlog.pgLocDr .sec1 .rt .bx {border: 1px solid #D6D6D6; border-radius: 10px; padding:20px; display: flex; text-align: center; font-size: 18px; line-height: 1.2; margin: 0px auto 30px 0px; max-width: 480px; gap:20px;}
.pgBlog.pgLocDr .sec1 .rt .bx .it {width: 50%;}
.pgBlog.pgLocDr .sec1 .rt .bx .it .t3 {text-transform: uppercase; font-weight: 500; margin-bottom: 6px;}
.pgBlog.pgLocDr .sec1 .rt .bx .it .t4 {font-size:24px; color:#7D65AC; font-weight: 600;}
.pgBlog.pgLocDr .sec1 .rt p {margin-bottom: 20px;}
.pgBlog.pgLocDr .sec1 .rt b {font-weight: 600;}
.pgBlog.pgLocDr .sec1 .rt .t5 {font-size: 24px; font-weight: 600; line-height: 1.2; margin:40px 0px 20px 0px;}
.pgBlog.pgLocDr .sec1 .rt .li {padding-left:24px; position: relative; padding-bottom: 10px; line-height: 1.2;}
.pgBlog.pgLocDr .sec1 .rt .li:before {content: ''; position: absolute; top: 5px; left: 0px; width: 10px; height: 10px; background-color: #955EDA; border-radius: 50%;}

.pgProduct .section {margin-bottom: 0px; padding-top: 80px; padding-bottom: 80px;}
.pgProduct .title {text-align: left;}
.pgProduct .sec1 {padding-top:0px; padding-bottom: 0px;}
.pgProduct .sec1 .pc {background-color: #000000;}
/* .pgProduct .sec1 img {opacity:0.8;} */
.pgProduct .sec2 .item {background-color:#f2f2f2; padding:40px; border-radius: 20px; transition: 0.5s;}
.pgProduct .sec2 .t1 {font-size: 30px; font-weight: 700; line-height: 1; margin-bottom: 10px;}
.pgProduct .sec2 .t2 {font-size: 18px; font-weight: 500; line-height: 1.2; margin-bottom:20px;}
.pgProduct .sec2 .t3 {font-size: 16px; font-weight: 500; line-height: 1.2; margin-bottom:6px;}
.pgProduct .sec2 .t4 {float:left; font-size: 16px; font-weight: 500; line-height: 1.2; text-align: center; margin-bottom:36px; background: #C7E0F2; border-radius: 26px; padding: 12px 30px;}
.pgProduct .sec2 .pr {display: flex; margin-bottom:20px;}
.pgProduct .sec2 .pr .t5 {margin:0px 40px 0px 0px; font-size: 22px; font-weight: 600; line-height: 1; color: #858585; text-decoration: line-through;}
.pgProduct .sec2 .pr .t6 {margin:0px; font-size: 22px; font-weight: 700; color: #7D65AC; line-height: 1;}
.pgProduct .sec2 .t7 {font-size: 18px; font-weight: 600; line-height: 1; color:#3B3B3B; margin-bottom: 30px;}
.pgProduct .sec2 .t7 span {font-weight:700;}
.pgProduct .sec2 .t8 {font-size: 20px; font-weight: 600; line-height: 1; text-transform: uppercase; color:#7D65AC; text-align: center; margin:20px 0px 10px 0px;}
.pgProduct .sec2 .t9 {font-size: 16px; font-weight: 600; line-height: 1; color:#3B3B3B; text-align: center; margin-bottom:40px;}
.pgProduct .sec2 .li {font-size: 18px; font-weight: 600; line-height: 1.2; padding:10px 0px 10px 50px; background-repeat: no-repeat; background-position: 10px 2px;background-image: url(/public/images/ic_tick.svg); background-size: 34px; color:#3B3B3B; background-size: 30px;}
.pgProduct .sec2 .lks {display: flex; gap:20px;}
.pgProduct .sec2 .lks a:nth-child(1) {background: transparent;border: 1px solid #000; color: #000;}
.pgProduct .sec2 .lks .btn_1:nth-child(1) {background: transparent;border: 1px solid #000; color: #000;}

.pgProduct .sec2 .lks a {width:100%;}
.pgProduct .sec2 .lks .btn_1 {width:100%; cursor: pointer;}

.pgProduct .sec2 .lks a:hover:nth-child(1) {border: 1px solid #7D65AC; color: #7D65AC;}
.pgProduct .sec3 {background-color: #f2f2f2;}
.pgProduct .sec3 .item {background-color: #ffffff; border-radius: 20px; padding:20px 30px;}
.pgProduct .sec3 .bx .t1 {float:left; font-size: 16px; font-weight: 500; line-height: 1.2; background-color: #7D65AC; color:#ffffff; padding:10px 30px; border-radius: 50px; margin-bottom: 20px;}
.pgProduct .sec3 .bx .ct {display: flex; align-items: center;}
.pgProduct .sec3 .bx .ct .pc {width:40%;}
.pgProduct .sec3 .bx .ct .pc img {border-radius: 20px;}
.pgProduct .sec3 .bx .ct .t2 {font-size: 18px; font-weight: 500; line-height: 1.3; width: 60%; padding-left: 30px;}
.pgProduct .sec4 .lt .pc {display: none;}
.pgProduct .sec4 .rt .dv {}
.pgProduct .sec4 .rt .dv .pc img {border-radius: 10px;}
.pgProduct .sec4 .rt .dv .tx {padding-top: 30px;}
.pgProduct .sec4 .rt .dv .tx .t1 {font-size: 18px; font-weight: 700; line-height: 1; margin-bottom: 10px;}
.pgProduct .sec4 .rt .dv .tx .t2 {font-size: 14px; font-weight: 600; line-height: 1.3;}
.pgProduct .sec5 {background-color: #f2f2f2;}
.pgProduct .sec5 .item {position:relative;  border-radius: 20px; overflow: hidden; background-color: #ffffff;}
.pgProduct .sec5 .dv {position:relative; overflow: hidden;}
.pgProduct .sec5 .pc img {transform-origin: center; transition: 0.5s;}
.pgProduct .sec5 .play {position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); cursor: pointer; width: 60px;}
.pgProduct .sec5 .play img {z-index: 9; position: relative;}
.pgProduct .sec5 .play:before {position: absolute; content:""; width:80px; height:80px; margin:-10px 0px 0px -10px; background-color: #7d65ac; border-radius: 50%; transform-origin: center; transform: scale(0); transition: 0.5s cubic-bezier(.17,.67,.15,1.26); opacity: 0;}
.pgProduct .sec5 .tx {display: flex; justify-content: space-between; padding: 20px 20px;}
.pgProduct .sec5 .tx .l {font-size: 22px; font-weight: 700; line-height: 1; margin:0px;}
.pgProduct .sec5 .tx .r {margin:0px;}
.pgProduct .sec5 .tx .r .t1 {font-size: 18px; font-weight: 600; line-height: 1; color: #7D65AC; margin-bottom: 6px;}
.pgProduct .sec5 .tx .r .t2 {font-size: 16px; font-weight: 600; line-height: 1; color:#3B3B3B;}
.pgProduct .sec6 .item {border: 1px solid #DDDDDD; border-radius: 20px; padding:30px;}
.pgProduct .sec6 .nm_ct {position: relative; min-height:80px; padding:30px 0px 0px 100px; margin-bottom:20px;}
.pgProduct .sec6 .nm_ct .pc {position:absolute; top:0; left:0; width:80px; height:80px; object-fit: cover; border-radius: 50%; overflow: hidden;}
.pgProduct .sec6 .nm_ct .nm {font-size: 20px; font-weight: 600; line-height: 1.2;}
.pgProduct .sec6 .st {float:left; position:relative; background-color: #e2e2e2; margin-bottom:30px; width: 120px;}
.pgProduct .sec6 .st .bg {position:absolute; top:0; left:0; width:50%; height:100%; background-color:#ffcc35;}
.pgProduct .sec6 .st .pc {position:relative;}
.pgProduct .sec6 .st .pc img {width: 100%;}
.pgProduct .sec6 .tx {font-size: 16px; font-weight: 400; line-height: 1.5;}
.pgProduct .sec6 .btn_1 {margin:40px auto 0px auto; width:185px;}
.pgProduct .sec7 {background-color: #f2f2f2;}
.pgProduct .sec7 .t1 {font-size: 22px; font-weight: 600; line-height: 1.2; margin-bottom: 10px; clear:both;}
.pgProduct .sec7 .pc {position: relative; margin-bottom:30px; overflow: hidden; float:left; width:50%;}
.pgProduct .sec7 .pc img {transform-origin: center; transition: 0.5s; height: 340px; object-fit: cover;}
.pgProduct .sec7 .pc1 {border-radius: 20px 0px 0px 20px; padding-right:1px;}
.pgProduct .sec7 .pc2 {border-radius: 0px 20px 20px 0px; padding-left:1px;}
.pgProduct .sec7 .lbl {position:absolute; top:20px; left:20px; font-size: 16px; line-height:1; font-weight: 500; padding:8px 16px; border-radius: 6px; background: #7D65AC; color:#ffffff;}
.pgProduct .sec7 .t2 {font-size: 16px; font-weight: 500; line-height: 1.2; color: #3b3b3b; margin-top:10px;}
.pgProduct .sec8 table {width: 100%;}
.pgProduct .sec8 table td {border-bottom: 1px solid #DFDFDF; vertical-align: middle; padding:10px; text-align: center; font-size: 18px; font-weight: 600; line-height: 1.2;}
.pgProduct .sec8 table td:nth-child(1) {text-align: left;}
.pgProduct .sec8 table td:nth-child(2) {background: #f2f2f2;}
.pgProduct .sec8 table td img {margin:0px auto;}
.pgProduct .sec8 table tr:nth-child(2) img {max-width: 60px; width: 100%;}
.pgProduct .sec8 table tr:nth-child(2) td:nth-child(1) {font-weight: 700;}
.pgProduct .sec8 table .hd td {font-size: 18px;}
.pgProduct .sec8 table .hd td:nth-child(2) {background: #7D65AC; color:#ffffff; border-radius: 20px 20px 0px 0px;}
.pgProduct .sec8 table td img {max-width: 30px; width: 100%;}
.pgProduct .sec8 table td:nth-child(2) {width:20%;}
.pgProduct .sec8 table td:nth-child(3) {width:20%;}
.pgProduct .sec8 table td:nth-child(4) {width:20%;}

.pgProduct .sec9 {background-color: #f2f2f2;}
.pgProduct .sec9 .ct {border-top: 1px solid #000000; margin-top:40px;}
.pgProduct .sec9 .acc {border-bottom: 1px solid #000000; padding:30px 30px;}
.pgProduct .sec9 .acc_btn {position:relative; font-size: 22px; font-weight: 600; line-height: 1.2; cursor: pointer; padding-right:50px;}
.pgProduct .sec9 .acc_btn:after {content: ''; position:absolute; top:-6px; right:-7px;  width: 40px; height: 40px; background: url(/public/images/icon_plus.svg) no-repeat center; background-size: 80%;}
.pgProduct .sec9 .acc_btn.active:after {background-image: url(/public/images/icon_minus.svg);}
.pgProduct .sec9 .acc_tx {font-size: 18px; font-weight: 500; line-height: 1.4;}
.pgProduct .sec9 .acc_tx .t1 {padding-top:20px;}

.pgProduct .sec10 .title {position: relative; padding-right:340px;}
.pgProduct .sec10 .title .btn_1 {position:absolute; right:0px; top:0px; width:320px;}
.pgProduct .sec10 .tp {height:30vw; margin-bottom: 40px;}
.pgProduct .sec10 .tp .dv1 {position:relative; float:left; width:60%; height:100%; padding-right:9px;}
.pgProduct .sec10 .tp .dv2 {position:relative; float:left; width:40%; height:100%;}
.pgProduct .sec10 .tp .dv2 .pc1 {height:50%; padding:0px 0px 9px 9px;}
.pgProduct .sec10 .tp .dv2 .pc2 {height:50%; padding:9px 0px 0px 9px;}
.pgProduct .sec10 .tp img {border-radius:10px; width:100%; height:100%; object-fit: cover;}
.pgProduct .sec10 .md {border-bottom: 1px solid #b5b5b5; padding-bottom:20px; margin-bottom: 20px; display: flex; gap:40px; font-size: 16px; font-weight: 500; line-height: 1.5; color:#3b3b3b;}
.pgProduct .sec10 .md .t1 {margin:0px auto 0px 0px; padding-left:34px; background: url(/public/images/ic_loc_green.svg) no-repeat 0px 0px; background-size: 20px;}
.pgProduct .sec10 .md .t2 {padding-left:34px; background: url(/public/images/ic_timer_green.svg) no-repeat 0px 0px; background-size: 24px;}
.pgProduct .sec10 .md .t3 {width:100%; max-width: 200px; padding-left:34px; background: url(/public/images/ic_phone_green.png) no-repeat 0px 0px; background-size: 24px;}
.pgProduct .sec10 .md .t4 {width:100%; max-width: 160px; margin:0px 0px 0px auto;}
.pgProduct .sec10 .bt {display:flex; gap:20px; font-size: 18px; font-weight: 600; line-height: 1.3; color:#3b3b3b; margin-bottom: 40px;}
.pgProduct .sec10 .dv {width: 25%; padding:10px 0px 10px 36px; background: url(/public/images/ic_tick.svg) no-repeat 0px 5px; background-size: 30px;}

.pgProduct .sec11 {padding-top: 0px;}
.pgProduct .sec11 .bx {display:grid; grid-template-columns:repeat(2,minmax(0,1fr)); gap:30px;}
.pgProduct .sec11 .t1 {font-size: 28px; font-weight: 700; line-height: 1; margin-bottom: 10px;}
.pgProduct .sec11 .lt {border: 1px solid #B5B5B5; padding:30px 20px; border-radius: 10px; width: 100%;}
.pgProduct .sec11 .lt .dv {position: relative; border-bottom: 1px solid #D6D6D6; padding:20px 0px 20px 60px;}
.pgProduct .sec11 .lt .dv .ic {position:absolute; left:0px; top:22px;}
.pgProduct .sec11 .lt .dv .t2 {font-size: 16px; font-weight: 400; line-height: 1.2; margin-bottom:6px;}
.pgProduct .sec11 .lt .dv .t3 {font-size: 18px; font-weight: 600; line-height: 1.2;}
.pgProduct .sec11 .lt .dv:last-child {border:none; padding-bottom: 0px;}
.pgProduct .sec11 .lt .dv a {color:#7D65AC; text-decoration: underline;}
.pgProduct .sec11 .rt {border: 1px solid #B5B5B5; padding:30px 20px; border-radius: 10px; width: 100%;}
.pgProduct .sec11 .rt .map {margin:20px 0px 10px 0px;}
.pgProduct .sec11 .rt .t2 {font-size: 20px; font-weight: 600; line-height: 1.2; color:#3b3b3b; margin-bottom: 20px;}
.pgProduct .sec11 .rt .t3 {font-size: 16px; font-weight: 400; line-height: 1.3; color: #3b3b3b; background: url(/public/images/ic_loc_green.svg) no-repeat 0px 0px; background-size: 20px; padding: 4px 0px 4px 30px; margin-bottom: 20px;}
.pgProduct .sec11 .rt .lk {font-size: 20px; font-weight: 600; line-height: 1.2; color:#7D65AC; text-decoration: underline; margin-bottom: 20px;}

.pgProduct .sec12 {background: #F2F2F2;}
.pgProduct .sec12 .title {margin-bottom:30px;}
.pgProduct .sec12 .item {border: 1px solid #B5B5B5; border-radius: 20px; padding: 20px; background-color: #ffffff;}
.pgProduct .sec12 .item .pc {position:relative; margin-bottom: 20px; overflow: hidden; border-radius: 5px;}
.pgProduct .sec12 .item .pc img {transition: 0.5s; transform-origin: center;}
.pgProduct .sec12 .item .tgs {position:absolute; left:0px; bottom:0px; padding:0px 20px 20px 20px; display: flex; font-size: 16px; font-weight: 400; line-height: 1.2; text-align: center;}
.pgProduct .sec12 .item .tgs .tg {padding:6px 16px; background: rgba(255,255,255,0.9); border-radius: 50px; margin-right:10px;}
.pgProduct .sec12 .item .t1 {font-size: 26px; font-weight: 600; line-height: 1; margin-bottom: 14px;}
.pgProduct .sec12 .item .t2 {font-size: 16px; font-weight: 600; line-height: 1.2; margin-bottom: 30px;}
.pgProduct .sec12 .item .btn_1 {background-color: transparent; border: 1px solid #000000; color:#000000;}

.pgProduct.pgOpen .sec7 {background-color: transparent;}
.pgProduct.pgOpen .title .tx {font-size: 18px; font-weight: 400; line-height: 1.5; color:#3b3b3b;}
.pgProduct.pgOpen .pc_tx {display: flex; align-items: center;}
.pgProduct.pgOpen .pc_tx_l {width: 50%; padding-right: 40px;}
.pgProduct.pgOpen .pc_tx_r {width: 50%; align-self: flex-start;}
.pgProduct.pgOpen .pc_tx_r img {width: 100%; border-radius: 10px;}
.pgProduct.pgOpen .pc_tx.rev {flex-direction:row-reverse;}
.pgProduct.pgOpen .pc_tx.rev .pc_tx_l {padding-right: 0px; padding-left: 40px;}
.pgProduct.pgOpen .sec13 {padding-top:0px;}
.pgProduct.pgOpen .sec14 {background-color: #f2f2f2;}
.pgProduct.pgOpen .sec14 .ct {margin-top:20px;}
.pgProduct.pgOpen .sec14 .acc {border-bottom: 1px solid #000000; padding:20px 0px 20px 0px;}
.pgProduct.pgOpen .sec14 .acc_btn {position:relative; font-size: 18px; font-weight: 600; line-height: 1.2; cursor: pointer; padding-right:50px;}
.pgProduct.pgOpen .sec14 .acc_btn:after {content: ''; position:absolute; top:-8px; right:0px;  width: 40px; height: 40px; background: url(/public/images/ic_down.svg) no-repeat center right; background-size: 40%;}
.pgProduct.pgOpen .sec14 .acc_btn.active:after {background-image: url(/public/images/ic_up.svg);}
.pgProduct.pgOpen .sec14 .acc_tx {font-size: 16px; font-weight: 500; line-height: 1.4;}
.pgProduct.pgOpen .sec14 .acc_tx .t1 {padding-top:20px;}
.pgProduct.pgOpen .sec16 {background-color: #f2f2f2;}
.pgProduct.pgOpen .sec16 .title {margin-bottom: 30px;}
.pgProduct.pgOpen .sec16 .pc {position: relative; margin-bottom: 40px;}
.pgProduct.pgOpen .sec16 .pc .img {width: 100%; border-radius: 10px;}
.pgProduct.pgOpen .sec16 .play {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer;}
.pgProduct.pgOpen .sec16 .play img {position: relative; z-index: 1;}
.pgProduct.pgOpen .sec16 .play:after {position: absolute; content: ""; width: 120px; height: 120px; margin: -60px 0px 0px -60px; background-color: #7d65ac; border-radius: 50%; transform-origin: center; transform: scale(0); transition: 0.5s cubic-bezier(.17,.67,.15,1.26); opacity: 0; top: 50%; left: 50%;}



.pgProduct.pgOpen .sec16 .grd .dv {position:relative; float:left; width: 50%; padding-left: 55px; padding-top: 10px; margin-bottom: 30px;}
.pgProduct.pgOpen .sec16 .grd .dv:nth-child(2n + 1) {padding-right:40px; clear:both;}
.pgProduct.pgOpen .sec16 .grd .dv .t1 {font-size: 24px; font-weight: 700; line-height: 1; text-align: center; position: absolute; top:0px; left:0px; background: #4AA472; color:#ffffff; width: 40px; height: 40px; border-radius: 50%; padding-top:8px;}
.pgProduct.pgOpen .sec16 .grd .dv .t2 {font-size: 24px; font-weight: 600; line-height: 1; margin-bottom: 10px;}
.pgProduct.pgOpen .sec16 .grd .dv .t3 {font-size: 16px; font-weight: 400; line-height: 1.5; color: #3b3b3b;}




.toggle-container {transition: height 0.35s ease-in-out; overflow: hidden;}
.toggle-container:not(.active) {display: none;}



.nomargin {margin-bottom:0px !important;}


.pgCareer .sec1 .title h2 {max-width: 700px; font-weight: 500; margin-left:auto; margin-right: auto;}
.pgCareer .sec1 .tp {position:relative; border: 1px solid #D6D6D6; border-radius: 10px; padding:6px 110px 0px 20px; max-width: 860px; margin:0px auto 60px auto; overflow: hidden; font-size: 16px; font-weight: 400; margin-top:40px;}
.pgCareer .sec1 .tp .lk {position:absolute; right:0px; top:0px; background:url(/public/images/ic_search_white.svg) no-repeat center center #7D65AC; width:70px; cursor: pointer; height:100%; transition: 0.5s;}
.pgCareer .sec1 .tp .lk span {display: none;}
.pgCareer .sec1 .tp .ct {display: flex; gap:40px; position:relative;}
.pgCareer .sec1 .tp .ct:after {content: ''; position:absolute; left:50%; top:-10px; width: 1px; height: 76px; background-color: #D6D6D6;}
.pgCareer .sec1 .tp .dv {width:50%;}
.pgCareer .sec1 .tp .dv .t1 {text-transform: uppercase;}
.pgCareer .sec1 .tp .dv .inp {padding: 0px;}
.pgCareer .sec1 .bt {display: flex;}
.pgCareer .sec1 .lt .ct {width:340px; background-color: #f2f2f2; border-radius: 15px; padding:30px;}
.pgCareer .sec1 .lt .t1 {font-size: 18px; font-weight: 500; line-height: 1.2; text-transform: uppercase; margin-bottom: 10px;}
.pgCareer .sec1 .lt .t2 {font-size: 14px; font-weight: 400; line-height: 1.2; text-transform: uppercase; margin-bottom: 10px;}
.pgCareer .sec1 .lt .t2 span {color: #7D65AC; cursor: pointer;}
.pgCareer .sec1 .lt .ac { border-bottom: 1px solid #d1d1d1; padding:12px 0px;}
.pgCareer .sec1 .lt .ac .ac_bt {font-size: 20px; font-weight: 400; line-height: 1.2; cursor: pointer; background: url(/public/images/ar_acc.svg) no-repeat right 8px;}
.pgCareer .sec1 .lt .ac .ac_tx {font-size: 16px; font-weight: 400; line-height: 1.5; padding:10px 0px 10px 0px; display: none;}
.pgCareer .sec1 .lt .ac.active .ac_bt {background-image: url(/public/images/ar_acc_active.svg);}
.pgCareer .sec1 .lt .ac.active .ac_tx {display: block;}
.pgCareer .sec1 .rt {width: calc(100% - 340px); padding-left:40px;}
.pgCareer .sec1 .rt .sort {text-align: right; font-size: 16px; padding:0px 20px 20px 0px; font-weight: 400; background: url(/public/images/ar_down.svg) no-repeat right 0px top 8px;}
.pgCareer .sec1 .rt .sort span {color: #6B6B6B; text-transform: uppercase;}
.pgCareer .sec1 .rt .bx {position:relative; border: 1px solid #D6D6D6; border-radius: 15px; padding:30px 200px 30px 30px; margin-bottom: 20px;}
.pgCareer .sec1 .rt .bx .t1 { font-size: 24px; font-weight: 500; line-height: 1.2; margin-bottom: 14px;}
.pgCareer .sec1 .rt .bx .t2 {display: flex; gap:20px; font-size: 16px; font-weight: 400; line-height: 1.5; color:#3b3b3b; margin-bottom: 10px;}
.pgCareer .sec1 .rt .bx .t2 .lc {margin: 0px; background: url(/public/images/ic_loc_green.svg) no-repeat 0px 1px; padding-left:24px;}
.pgCareer .sec1 .rt .bx .t2 .dt {margin: 0px; background: url(/public/images/ic_calendar_green.svg) no-repeat 0px 1px; padding-left:24px;}
.pgCareer .sec1 .rt .bx .t3 {font-size: 16px; font-weight: 400; line-height: 1.5; color:#3b3b3b;}
.pgCareer .sec1 .rt .bx .btn_1 {position:absolute; top:50%; right:30px; width:140px; transform: translateY(-50%);}

/* CSS Changes on 21 apr */

.landing .title h1 {margin-bottom: 30px }
.landing .title h2 {margin-bottom: 30px !important;}

.landing .title.center {text-align: center;}
.landing .sec1 {padding-top:40px !important; max-width: 700px;}
/* .landing .sec1 input[type=text] {border:1px solid #d9d9d9; height:56px;} */
.landing .sec1 input[type=text] { height:56px;}
.landing .sec1 button {font-size: 16px; width: 100%; background-color: #7D65AC; color: #ffffff; font-family: 'Montserrat', sans-serif; outline: none; border: none; height: 50px; border-radius: 8px; font-weight: 600; line-height: 1; text-align: center; cursor: pointer;}
.landing .sec1 button:disabled {opacity: 0.5; cursor: default;}
.landing .sec1 .fd1 {margin-bottom: 20px;}
.landing .sec1 .fd2 {margin-bottom: 20px; border:1px solid #d9d9d9; height:56px; border-radius: 6px; display: flex; align-items: center;}
.landing .sec1 .fd2 .lbl {font-weight: 500; padding-left:10px;}
.landing .sec1 .fd2 input[type=text] {border:none; height: 54px;}
.landing .sec1 .fd3 {margin-bottom: 20px;}
.landing .sec1 .fd4 {margin-bottom: 20px; font-size: 16px; font-weight: 500;}
.landing .sec1 .fd5 {display:flex; gap:20px; margin-bottom: 20px;}
.landing .sec1 .fd6 {margin-bottom: 20px;}
.landing .sec1 .fd7 {display: flex; gap:20px;  margin-bottom: 20px;}
.landing .sec1 .fd7 .tx {font-size: 14px; font-weight: 400; line-height: 1.5; color: #3b3b3b;}



.landing .sec2 .lks {padding-top:20px;}
.landing .sec2 .fd {display: flex; gap:60px; align-items: center;}
.landing .sec2 .fd_item {width:100%;}
.landing .sec2 .t2 {font-size:16px; line-height: 1.5;}

.landing .sec9 {background-color: #ffffff;}

.landing .sec20 {margin-top:60px; background-color: #f2f2f2; padding:40px 0px 0px 0px !important;}
.landing .sec20 .container {display:grid; grid-template-columns:repeat(4,minmax(0,1fr)); gap:0px; font-weight: 500; font-size: 14px; text-align: center; align-items: center;}
.landing .sec20 .dv {border-right: 1px dashed #666; width: 100%; margin-bottom: 40px;}
.landing .sec20 .dv:last-child{ border: none;}
.landing .sec20 .t1 {font-weight: 600; color:#7D65AC; font-size: 18px;}



.smile .sec1 {font-weight: 400;}
.smile .sec1 .container {margin-bottom: 80px;}
.smile .sec1 .sm_tx1 {margin-bottom: 20px;}
.smile .sec1 .sm_tx1 span {color:#7D65AC; font-weight: 700;}
.smile .sec1 .sm_tx1 b {color:#7D65AC; font-weight: 700;}
.smile .sec1 .sm_tx2 {font-weight: 700; font-size: 16px;}
.smile .sec1 .sm_tx3 {font-size: 16px; margin-bottom: 10px;}
.smile .sec1 .fd3 {display: flex; gap: 10px;}
/* .pgProduct.landing.smile {padding-bottom: 100px;} */


.whyChooseWhistle {}
.whyChooseWhistle .swiper-slide {background-color: #fff; border-radius: 20px;}
.whyChooseWhistle .pc {width: 100% !important; margin-bottom: 20px;}
.whyChooseWhistle .t1 {min-height: 60px; display: flex; align-items: center; width: 100%;}
.whyChooseWhistle .t2 {width: 100% !important; padding: 0px !important;}

#chatbot--window {right:0px !important;}
.chat-class{bottom: 74px !important;}


.smiletest {padding-top: 60px;}
.smiletest .topnav2 {display: block !important;}
.smiletest .topnav2 .container {gap: 0px; max-width: 840px; padding: 10px}
.smiletest .topnav2 .bar {position:relative; width:calc(100% - 50px); background-color: #ccc; height: 10px; border-radius: 10px;}
.smiletest .topnav2 .bar .bar_val {position:absolute; width:0%; background-color: #7D65AC; height:100%; border-radius: 10px; transition: 0.5s;}
.smiletest .topnav2 .lbl {width: 60px; text-align: right; font-weight: 700;font-size:22px;}

.smiletest .sec1 {padding-top: 30px; margin-bottom: 50px; font-weight: 500;}
.smiletest .sec1 .tx {margin-bottom: 20px; font-size: 16px;}

.smiletest .sec1 .tx.opt1 {text-align: left; max-width: 840px;}
.smiletest .sec1 .tx.opt1 .dv {margin-bottom: 10px;}
.smiletest .sec1 .tx.opt1 .dv b {font-weight: 700;}

.smiletest .sec1 .bx {display: flex; display:grid; grid-template-columns:repeat(4,minmax(0,1fr)); gap:20px; font-size: 16px; line-height: 1.2; max-width: 820px;}
.smiletest .sec1 .bx .dv {display: flex; align-items: center; cursor: pointer; border:2px solid #dddddd; border-radius: 10px; padding:10px; text-align: center; width: 100%; transition: 0.5s;}
.smiletest .sec1 .bx .dv .img_cnt {position: relative; border-radius: 10px; overflow: hidden; }
.smiletest .sec1 .bx .dv .img_cnt .pc {position: absolute; top:0px; left:0px;}
.smiletest .sec1 .bx .dv .img_cnt .pc {position: absolute; top:0px; left:0px;}
/* .smiletest .sec1 .bx .dv .img_cnt .pc:nth-child(1) {opacity:1;} */
/* .smiletest .sec1 .bx .dv .img_cnt .pc:nth-child(2) {opacity:0; animation: pulse 6s infinite;} */
.smiletest .sec1 .bx .dv p {font-weight: 700; padding:10px 0px 0px 0px;}
.smiletest .sec1 .bx .dv.active {border-color: #7D65AC; background-color: #7D65AC; color:#fff;}

.smiletest .sec1 .bx.opt1 {padding-top:20px; grid-template-columns:repeat(3,minmax(0,1fr));}
.smiletest .sec1 .bx.opt2 {padding-top:20px; grid-template-columns:repeat(1,minmax(0,1fr)); gap: 10px;}
.smiletest .sec1 .bx.opt3 {padding-top:20px; grid-template-columns:repeat(4,minmax(0,1fr)); gap: 10px;}
.smiletest .sec1 .bx.opt2 .dv {text-align: left; font-weight: 500; border:2px solid #dddddd; width: 100%; padding:14px; border-radius: 10px; cursor: pointer;}
.smiletest .sec1 .bx.opt3 .dv {text-align: center; font-weight: 500; border: 2px solid rgb(221, 221, 221); width: 100%; padding: 20px 14px; border-radius: 10px; cursor: pointer; display: flex; justify-content: center;}
.smiletest .sec1 .selfie-img-label {border: 2px solid #ddd; margin:0px auto 40px auto; border-radius: 5px; cursor: pointer; padding: 8px 16px; font-size: 16px; font-weight: 600; width: 10rem; height: 3.5rem; display: flex; justify-content: center; align-items: center;}
.smiletest .sec1 .selfie-img-label span {display: inline-block; vertical-align: middle; margin-right: 10px;}
.smiletest .sec1 .selfie-img-label input[type=file] {position: absolute; left: -9999px;}
.smiletest .sec1 .btn_1 {margin:0px auto; width: 100%; max-width: 280px; border:none; outline:none; font-family: 'Montserrat', sans-serif; cursor: pointer; font-size: 20px; padding:0px;}

.ic img{width:70%;}


.thank .sec1 {font-weight: 500; padding-top: 60px;}
.thank .sec1 .container {display: flex; align-items: center; gap:20px; max-width:1200px;}
.thank .sec1 .lt {width: 40%;}
.thank .sec1 .lt img {width: 100%;}
.thank .sec1 .rt {width: 60%;}
.thank .sec1 .rt .tx {margin-bottom: 30px;}
.thank .sec1 .rt .tx2 {font-weight: 700; margin-bottom: 10px;}
.thank .sec1 .rt .tx2 span {color:#7D65AC;}
.thank .sec1 .rt .tx3 {font-size: 14px;}
/*  */

footer {background: #353535; color:#ffffff;}
footer .tp {border-bottom:1px solid #484848; padding:50px 0px;}
footer .tp .ttl {font-size: 26px; font-weight: 700; line-height: 1.3; margin-right:10px; margin-top:8px;}
footer .tp .container {max-width: 1370px;}
footer .tp .dv {float:left; margin:0px 4px;}
footer .tp .dv input[type=text] {background-color: #494949; width:265px; height:50px; color:rgba(255,255,255,0.7); font-size: 14px; font-weight: 400; line-height: 14px; padding-right: 50px;}
footer .tp .dv.inp {position:relative;}
footer .tp .dv.inp:after {position: absolute; content: ''; width: 50px; height: 50px; top:0px; right:0px; background-repeat:  no-repeat; background-position:  center center;}
footer .tp .dv.inp:nth-child(2):after {background-image: url(/public/images/ic_name_small.svg);}
footer .tp .dv.inp:nth-child(3):after {background-image: url(/public/images/ic_phone_small.svg);}
footer .tp .dv.inp:nth-child(4):after {background-image: url(/public/images/ic_mail_small.svg);}
footer .tp .dv button {margin-left:10px; color:black;  height:50px; width:150px; outline: none;  border: none; font-family: 'Montserrat'; font-size: 16px; font-weight: 700; line-height: 18px; background: linear-gradient(180deg, #FFFFFF 0%, #D5D5D5 126.76%, rgba(255, 255, 255, 0) 137.5%); border-radius: 25px;}
footer .md {border-bottom:1px solid #484848; padding:50px 0px;}
footer .md .lg img {margin:0px auto 30px auto; width: 150px;}
footer .md .grd .dv {float:left; width: 20%; display: flex; flex-direction: column; align-items: start; padding-right:20px;}
footer .md .grd .dv:nth-child(3) {width:24%;}
footer .md .grd .dv:nth-child(4) {width:16%;}
footer .md .grd .dv a {color:#ffffff; font-size: 16px; font-weight: 400; line-height: 20px; padding-bottom:12px;}
footer .md .grd .dv .ttl {font-size: 16px; font-weight: 600; line-height: 1.2; margin: 0px 0px 20px 0px; padding-bottom: 0px;}
footer .md .grd .dv .lk1 {font-size: 16px; font-weight: 500; line-height: 1.2; }
footer .md .grd .dv .lk2 {font-size: 16px; font-weight: 500; line-height: 1.2; padding-top: 4px;}
footer .md .grd .dv .ics {display: flex; justify-content: center; align-items: center; margin:20px 0px 0px 0px; gap:20px;}
footer .bt {padding:30px 0px; font-size: 14px; font-weight: 400; line-height: 18px; letter-spacing: 1px; text-align: center;}
footer .bt .container {display: flex; max-width: 780px;}
footer .bt a {color:#ffffff;}
.managed_by{margin: 0 auto;text-align: center;font-size: 13px;margin-top:20px;}
.managed_by a{color:#fff;}

.ftr_mobile {position: fixed; bottom: 0px; left: 0px; width:100%; background-color: #eeeeee; display: flex; font-size: 12px; font-weight: 500; line-height: 15px; align-items: end; padding:6px 0px; display:none; z-index: 9999;}
.ftr_mobile a {color:#000000; width:25%; text-align: center;}
.ftr_mobile a img {margin: 0px auto 6px auto;}
.ftr_mobile .mobile-book  {color:#000000; width:25%; text-align: center;}
.ftr_mobile .mobile-book img {margin: 0px auto 6px auto; width: 40px;}


.aligner-wrapper{
    padding-top: 80px;
    padding-bottom: 80px;
}
.aligner-wrapper .row{
display: grid;
grid-template-columns:repeat(2,minmax(0,1fr));
gap: 28px;
}

.aligner-wrapper .title h4{
margin-top: 20px;
}

.aligner-wrapper .mw-630{
    max-width: 630px;
}
.aligner-wrapper .mw-630 ul{
    margin-left: -10px;
}
.aligner-wrapper .mw-630 ul li{
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 10px 0px 10px 50px;
    background-repeat: no-repeat;
    background-position: 10px 2px;
    background-image: url(/public/images/ic_tick.svg);
    background-size: 34px;
    color: #3B3B3B;
    background-size: 30px;

}
/* .aligner-wrapper .col-50 ul li::before{
content: url("../images/check-input.svg");
position: absolute;
top: 0;
left: 0;
width: 30px;
} */

.aligner-wrapper .mw-630 p {
    margin-bottom: 24px;
}
.aligner-wrapper.aligner-2-0{
padding-top: 0;
padding-bottom: 0;
}
.aligner-wrapper.aligner-2-0 .mw-630{
    padding-right: 50px;
    max-width: 100%;
}

 /* 10 april 2024 */

 /* section.section.sec3.rev .rt .t1{
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;

 } */
 section.section.sec3.rev .rt .t1{
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #7D65AC;
 }
 section.section.sec3.rev .rt .t1 span{
    color:#7D65AC;
 }
 section.section.sec3.rev .rt .t2 {
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 40px;
}
footer .bt .container {
    display: flex;
    max-width: 1172px;
}   
.highlight-text{
    padding: 20px;
    font-size: 16px;
    color: #5a23c4;
    font-weight: 500;
    padding-left: 0;
}
.content-cms{
    max-width: 1640px;
    padding: 0px 100px;
    padding-bottom: 80px;
}
.content-cms p{
font-weight: 400!important;
font-size: 16px!important;
}
.content-cms h2{
    font-size: 22px;
    font-weight: 600;
}
.content-cms p span, .content-cms h1 span{
    font-size: 16px!important;
    font-family: inherit!important;
    font-weight: 400!important;
}
@keyframes pulse {
	0% {
		opacity:0
	}
	25% {
		opacity:1
	}
    50% {
		opacity:1
	}
    75% {
		opacity:0
	}
	100% {
		opacity:0
	}
}

@media (min-width:1800px){
 
    .aligner-wrapper .row{
        align-items: center;
    }
}

@media(max-width:1900px){
    .banner .item .tx {left:40px;}
    header {height: 100px;}
    body {padding-top: 100px;}
    header .lt {width: 180px;}
    header .md {font-size: 16px; transform: translate(-64%, -50%);}
    header .rt {margin-top: 29px;}
    header .rt .btn_menu {font-size: 16px;}
    
}

@media(max-width:1900px){
    
    
    .hp_sec2 .tx {font-size: 24px; line-height: 28px;}
    .hp_sec4:before {height: 35vw;}
    footer .tp .container {max-width: 1310px;}
    footer .tp .dv input[type=text] {width: 275px;}
}

@media(max-width:1650px){
    
    .pgLocation .sec1 .grd {font-size: 16px;}
    .pgLocation .sec1 .grd .dv .bx input[type=text] {width: 260px; font-size: 14px;}
    .pgLocation .sec1 .grd .dv .bx .l {width:200px;}
    .pgLocation .sec1 .grd .dv .bx .l .tx {font-size: 14px; margin-top: 2px;}
	
	.aligner-wrapper .col-50 img{
        width: 100%;
    }
  

}

@media(max-width:1400px){
    .pgAbout .sec3 .rt {padding-left: 50px;}
    .pgAbout .sec5 .item .dv {padding:16px 20px;}
    .pgAbout .sec4 .item .dv .t2 {font-size: 18px;}
    .pgProduct .sec8 table br {display: none;}
    
}
@media(max-width:1350px){
    .pgLocation .sec1 .grd .dv:nth-child(5) {clear:both; margin:0px}
    .pgLocation .sec1 .grd .dv:nth-child(5) a {margin-top:20px;}
}
@media(max-width:1050px){
    .pgLocation .sec1 .grd .dv .bx input[type=text] {width:180px;}
}

@media(max-width:1300px){
    footer .tp .container {max-width: 1160px;}
    footer .tp .dv input[type=text] {width: 195px;}
	section.aligner-wrapper .title h2{
        font-size: 22px;
        }
        section.aligner-wrapper  .title h3 {
            font-size: 18px;
            margin-bottom: 21px;
        }
        .aligner-wrapper .mw-630 p{
            font-size: 16px;
            margin-bottom: 20px;
        }
        .aligner-wrapper .title h4 {
            margin-top: 10px;
        }
        .aligner-wrapper.aligner-2-0 .mw-630{
            padding-right: 0;
        }
}

@media(max-width:1200px){

    .container {padding: 0px 40px;}

    .pgBlog.pgLocDr .sec1 .lt .lks { flex-direction: column;}
    .pgBlog.pgLocDr .sec1 .lt .lc {width: 100%; max-width: max-content; padding-right: 30px; padding-left: 20px;}
    .pgBlog.pgLocDr .sec1 .lt .btn_1 {width: 100%; max-width: max-content; padding-right: 20px; padding-left: 20px; margin:0px auto;}
    
    header {background-color: #ffffff;}
    .hdr_mn .container {min-height: unset;}
    .mb_menu {position:fixed; top:0px; right:-300px; width:0px; height: calc(100vh); background: #7d65ac; overflow-y: scroll; transition: 0.5s; z-index: 1; padding-top:60px;}
    .mb_menu.active {right:0px; width:360px;}
    .hdr_mn {width:100%; right:0px; height:auto; overflow-y:visible;}
    .hdr_mn .container {margin:0px; width:100%; padding-top:0px;}
    .mb_menu .close_menu {right:0px;}
    .scroll .mb_menu { height: calc(100vh); top: 0px;}
    
    header .md {display: none; position: relative; top:0px; transform: none; left:0px; font-size: 18px; padding:0px 20px;}
    header .md .mn {width:100%;}
    header .md .mn .lk {padding: 14px 0px; border-bottom: 1px solid #c4a8e6;}
    header .md .mn .mn_lk {position: relative; top:0px; left:0px;}
    .hdr_mn {position: relative; top:0px; background: transparent; display:block !important;}
    

    header .md .mn.active .lk {color:#7d65ac; border:none}
    header .md .mn.active:after {background-image:url(/public/images/ar_down_active.png)}
    header .md .mn.active .mn_lk {display: block;}
    header .md .mn .mn_lk {box-shadow: none; border-bottom: 1px solid #c4a8e6; border-radius: 0px; padding-bottom: 30px;}
    header .md .mn .mn_lk a {border:none; padding: 6px 20px;}
    header .md .mn.has_menu:after {top:18px;}

    .banner .item {}
    .banner .item .pc {height: 100%;}
    
    .topbanner .tx {left: 40px;}
    .topbanner h1 {left: 40px;}


    .pgLocation .sec2 .grd {grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 20px;}
    .pgLocation .sec1 .grd .dv .btn_1 {height: 52px; padding-top: 17px;}
    .pgMedia .sec2 .grd {grid-template-columns: repeat(2, minmax(0px, 1fr));}
    .pgBlog .sec1 .grd {grid-template-columns: repeat(2, minmax(0px, 1fr));}

    .pgProduct .sec4 .container {flex-direction: column;}
    .pgProduct .sec4 .lt {padding:0px;}
    .pgProduct .sec4 .title .pc {width:300px; margin:0px auto 40px 0px;}
    .pgProduct .sec4 .title .pc img {width: 100%;}
    .pgProduct .sec4 .rt {width: 100%;}
    .pgProduct .sec4 .rt .dv .tx {padding-left: 0px;}
    

    .pgBlog.pgLocDr .sec1 .ct {flex-direction: column;}
    .pgBlog.pgLocDr .sec1 .lt {width: 100%; margin-bottom: 40px;}
    .pgBlog.pgLocDr .sec1 .rt {width: 100%; padding:0px;}
    .pgBlog.pgLocDr .sec1 .rt .t1 {font-size: 32px;}
    .pgBlog.pgLocDr .sec1 .rt h1 {font-size: 32px;}
    .pgBlog.pgLocDr .sec1 .ct {margin-bottom: 40px;}
    .pgBlog.pgLocDr .sec1 .lt .pc {max-width: 500px; margin:0px auto 20px auto}
    .pgBlog.pgLocDr .sec1 .lt .btn_1 {height: 42px; min-height: unset;}
    .pgBlog.pgLocDr .sec1 .lt .lc {min-height: 40px; padding:6px 30px 6px 20px;}

    .pgBlog.pgDetail .sec0 .lft {width:100%;}
    .pgBlog.pgDetail .sec0 .rgt {width:100%; padding:0px;}
    .pgBlog.pgDetail .sec1 {margin-bottom: 40px;}
    .pgBlog.pgDetail .sec0 .rgt {margin-bottom: 80px;}

    .popup_call.crapply .inputfile .lk .btn_1 {width: 100px;}
    .inputfile #name_ct {width: calc(100% - 130px);}
    .popup_call.viewdetail .lt .t1 {font-size: 30px;}
    .popup_call.viewdetail .lt .t2 {font-size: 18px;}
    .popup_call.viewdetail .rt .t2 {font-size: 18px;}

    .topnav {min-height:50px; display: none;}
    .topnav .mb_ttl {display: block; cursor: pointer;}
    .topnav .mb_ttl.active { background-image: url(/public/images/ic_cross.svg); background-size: 20px;}
    .topnav .container.active{position: absolute; top: 50px; left: 0px; background: #ffffff; padding: 6px 20px 20px 20px; width: 100%; flex-direction: column; gap:0px; display: flex;}
    .topnav .container {position: absolute; top: 50px; left: 0px; background: #ffffff; padding: 6px 20px 20px 20px; width: 100%; flex-direction: column; gap:0px; display: none;}
    .topnav .container .lk {padding:16px 0px 0px 0px; background: transparent; text-align: left; font-weight: 500; font-size: 16px; margin: 0px auto 0px 0px; border-bottom: 1px solid #000; border-radius: 0px;}
    .topnav .container .lk.active {background: transparent; color:#7d65ac; border-color: #7D65AC;}

    .pgCareer .sec1 .bt {flex-direction: column;}
    .pgCareer .sec1 .bt .lt {width:100%; margin-bottom:40px;}
    .pgCareer .sec1 .bt .lt .ct {width:100%;}
    .pgCareer .sec1 .bt .rt {width:100%; padding:0px;}

    .pgProduct .sec10 .title {padding-right:0px;}
    .pgProduct .sec10 .title .btn_1 {position: relative; margin-bottom: 30px;}
    .pgProduct .sec10 .title h3 {margin-bottom: 30px;}
    .pgProduct .sec10 .md {flex-direction: column; gap:14px;}
    .pgProduct .sec10 .md .t1,
    .pgProduct .sec10 .md .t2,
    .pgProduct .sec10 .md .t3,
    .pgProduct .sec10 .md .t4 {width:100%; margin:0px; padding-top:2px; padding-bottom:2px; }
    .pgProduct .sec10 .bt {display: grid; grid-template-columns:repeat(2,minmax(0,1fr)); gap:0px;}
    .pgProduct .sec10 .bt .dv {width: 100%;}
    .pgProduct .sec12 .item .t1 {font-size: 20px;}
    .pgProduct .sec12 .item .t2 {font-size: 14px;}

     /* CSS Changes on 21 apr */ 

    .landing .sec20 .container {grid-template-columns: repeat(2, minmax(0, 1fr));}
    .landing .sec20 .dv:nth-child(2) {border: none;}

    /*  */
    footer .tp {text-align: center;}
    footer .tp .container {max-width: 640px;}
    footer .tp .dv {width:100%; margin:0px 0px 13px 0px;}
    footer .tp .dv input[type=text] {width: 100%;}
    footer .tp .ttl {margin: 0px 0px 7px 0px;}

    footer .md {padding: 50px 0px 10px 0px;}
    footer .md .grd .dv {width: 33.33%; margin-bottom:40px;}
    footer .md .grd .dv:nth-child(3) {width: 33.33%;}
    footer .md .grd .dv:nth-child(4) {width: 33.33%;}
    footer .md .grd .dv:nth-child(4) {clear:both; }
	
	 section.aligner-wrapper .title h2{
        font-size: 22px;
        }
        section.aligner-wrapper  .title h3 {
            font-size: 18px;
            margin-bottom: 21px;
        }
        .aligner-wrapper .mw-630 p{
            font-size: 16px;
            margin-bottom: 20px;
        }
        .aligner-wrapper .title h4 {
            margin-top: 10px;
        }
}

@media(max-width:980px){

    .banner .item .tx {font-size: 40px;}
    

    .popup_call .ct {flex-direction: column;}
    .popup_call .lt {display: none;}
    .popup_call .rt {width: 100%; padding:70px 40px 40px 40px;}
    .popup_call .ct2 {width: 100%; padding:70px 40px 40px 40px;}
    .popup_call.crapply .rt {width: 100%;}
    .popup_call.viewdetail .lt {display: block; width: 100%;}
    .popup_call.viewdetail .rt {width: 100%; padding: 0px 20px 20px 20px;}

    .hp_sec1 {margin-bottom:60px;}
    .hp_sec2 {margin-bottom:60px;}
    .hp_sec3 {margin-bottom:60px;}
    .hp_sec4 {margin-bottom:60px;}
    .hp_sec5 {margin-bottom:60px;}

    .hp_sec1 .item .cnt {gap:20px;}
    
    
    .hp_sec2 .tx {font-size: 22px; line-height: 26px; bottom: 30px; padding: 0px 30px;}
    .hp_sec3 .tx .t2 {font-size: 16px;}
    .hp_sec4 {padding-top: 50px;}
    
    
    .hp_sec2 .grad {opacity:1;}
    .hp_sec2 .tx {left:0px;}
    .hp_sec3 .tx {position: relative; transform: none; top: 0px; padding:20px;}
    .hp_sec3 .tx .t1 {font-size: 26px;}
    .hp_sec3 .tx .t1:after {width: 130px; bottom: -12px;  background-color: #7D65AC;}
    .hp_sec3 .tx .t1 {color:#000000;}
    .hp_sec3 .tx .t2 {color:#000000;}
    .hp_sec3 .swiper-slide {overflow: visible; background: #f2f2f2; border-radius: 20px;}
    .hp_sec3 .grad {display: none;}

    

    .btn_1 {font-size: 16px; height: 42px; padding-top: 13px;}

    .pgAbout .sec2 .item img {height: 250px;}
    .pgAbout .sec3 .container {grid-template-columns: repeat(1, minmax(0, 1fr));}
    .pgAbout .sec3 .title {width: 100%;}
    .pgAbout .sec3 .lt {margin: 0px 0px 40px 0px;}
    .pgAbout .sec3 .rt {padding-left: 0px;}
    .pgAbout .sec3.rev .lt {order: 0;}
    .pgAbout .sec3.rev .rt {padding-right: 0px;}

    .pgAbout .sec5 .item .dv .t2 {height:auto; margin-top:20px;}
    .pgAbout .sec5 .item .dv .bg {display: none;}

    .pgProduct .sec2 .t1 {font-size: 26px;}    
    .pgProduct .sec2 .item {padding: 30px 20px;}
    .pgProduct .sec2 .t2 {font-size: 16px;}
    .pgProduct .sec2 .t3 {font-size: 14px;}
    .pgProduct .sec2 .t4 {margin-bottom: 24px;}
    .pgProduct .sec2 .pr .t5 {font-size: 20px;}
    .pgProduct .sec2 .pr .t6 {font-size: 20px;}
    .pgProduct .sec2 .li {}
    .pgProduct .sec2 .t8 {font-size: 18px; line-height: 1.2;}
    .pgProduct .sec2 .t9 {font-size: 16px; margin-bottom: 30px;}
    .pgProduct .sec3 .bx .t1 {padding: 8px 16px;}
    .pgProduct .sec3 .item {padding:20px;}
    .pgProduct .sec3 .bx .ct .t2 {font-size: 18px;padding-left: 20px;}

    .pgProduct.pgOpen .pc_tx {flex-direction: column-reverse !important;}
    .pgProduct.pgOpen .pc_tx_l {width:100% !important; padding:0px !important;}
    .pgProduct.pgOpen .pc_tx_r {width:100% !important; padding:0px !important; margin-bottom: 40px;}

    .pgProduct .sec11 .bx {grid-template-columns: repeat(1, minmax(0, 1fr));}
    .pgProduct .sec10 .title .btn_1 {max-width: 320px; width: 100%; margin: 0px auto 30px auto;}
    .pgProduct .sec10 .tp {height: 40vw;}
    .pgProduct .sec10 .tp .dv1 {width: 100%; padding: 0px; margin-bottom: 20px;}
    .pgProduct .sec10 .tp .dv2 {width: 100%; height: auto; margin-bottom: 20px;}
    .pgProduct .sec10 .tp .dv2 .pc1 {padding:0px; height: 40vw; margin-bottom:20px;}
    .pgProduct .sec10 .tp .dv2 .pc2 {padding:0px; height: 40vw;}
    .pgProduct .sec10 .md {clear: both; font-size: 14px;}
    .pgProduct .sec10 .bt {grid-template-columns: repeat(1, minmax(0, 1fr)); font-size: 14px; margin-bottom: 0px;}
    .pgProduct .sec10 .dv {background-size: 22px; padding: 6px 0px 6px 30px; background-position-y: 3px;}
    .pgProduct .sec11 .lt {padding: 20px 14px;}
    .pgProduct .sec11 .rt {padding: 20px 14px;}
    .pgProduct .sec11 .t1 {font-size: 20px; text-align: center; margin-bottom: 0px;}
    .pgProduct .sec11 .lt .dv {padding-left:34px;}
    .pgProduct .sec11 .lt .dv .t2 {font-size: 12px; margin-bottom: 4px;}
    .pgProduct .sec11 .lt .dv .t3 {font-size: 14px;}
    .pgProduct .sec11 .lt .dv .ic {width:20px;}
    .pgProduct .sec11 .lt .dv .ic img {width:100%;}
    .pgProduct .sec11 .rt .map {margin-top:10px;}
    .pgProduct .sec11 .rt .map iframe {height:180px;}
    .pgProduct .sec11 .rt .t2 {font-size: 16px;}
    .pgProduct .sec11 .rt .t3 {font-size: 14px; padding-top:0px; margin-bottom:10px;}
    .pgProduct .sec11 .rt .lk {font-size: 16px;}

    .pgProduct.landing.smile .sec1 {flex-direction: column;}
    .pgProduct.landing.smile .sec1 .left_content {margin-bottom: 0px; padding-right: 0px;}
    
    header .blackbg {-webkit-backdrop-filter: unset; -o-backdrop-filter: unset; -moz-backdrop-filter: unset; backdrop-filter: unset;}
    
    /* CSS Changes on 21 apr */
    .smiletest .sec1 .bx {grid-template-columns: repeat(2, minmax(0px, 1fr));}
    .pgMedia .sec3 .title h2 br {display: none;}

    /*  */

	section.aligner-wrapper .title h2{
        font-size: 22px;
        }
        section.aligner-wrapper  .title h3 {
            font-size: 18px;
            margin-bottom: 21px;
        }
        .aligner-wrapper .mw-630 p{
            font-size: 16px;
            margin-bottom: 20px;
        }
        .aligner-wrapper .title h4 {
            margin-top: 10px;
        }

}


@media(max-width:900px){
.pgLocation .sec1 .grd .dv .bx input[type=text] {font-size: 18px;}
.pgLocation .sec1 .grd {max-width: 500px; margin: 0px;}
.pgLocation .sec1 .grd .dv {width: 100%;}
.pgLocation .sec1 .grd .dv .bx .r {width: 100%; padding-top: 6px;padding-bottom: 6px; }
.pgLocation .sec1 .grd .dv:nth-child(3),
.pgLocation .sec1 .grd .dv:nth-child(5)
{padding: 20px 0px 20px 0px; text-align: center;}
.pgLocation .sec1 .grd .dv .btn_1 {width: 100%;}
.pgLocation .sec1 .grd .dv .bx input[type=text] {width: 100%;}
.pgLocation .sec1 .grd .dv:nth-child(2) .bx {display: block; height: auto;}
.pgLocation .sec1 .grd .dv .bx .l {width: 100%; border-bottom: 1px dashed #ccc; padding-bottom: 14px;}
.pgLocation .sec1 .grd .dv .bx .l::after {display: none;}
.pgLocation .sec1 .title h3 {margin-bottom: 40px;}
.pgLocation .sec1 .grd .dv:nth-child(5) {padding-bottom: 0px;}
.pgLocation .sec1 .grd .dv:nth-child(5) a {margin-top: 0px;}
.pgLocation .sec1 .grd .dv .bx .l::before {padding-right: 30px;}
section.aligner-wrapper .title h2{
font-size: 22px;
}
section.aligner-wrapper  .title h3 {
    font-size: 18px;
    margin-bottom: 21px;
}
.aligner-wrapper .mw-630 p{
    font-size: 16px;
    margin-bottom: 20px;
}
.aligner-wrapper .title h4 {
    margin-top: 10px;
}
}

@media(max-width:767px){
    .banner-img.desk{display:none; }
.banner-img.mob{display:block; padding-left: 0 !important;}

    .pgHome .swiper-slide {margin:0px 0px 0px 16px !important;}
    .pgHome .banner .swiper-slide {margin:0px 0px 0px 0px !important;}
    .pgAbout .swiper-slide {margin:0px 0px 0px 16px !important;}
    .pgProduct .swiper-slide {margin:0px 0px 0px 16px !important;}

    .container {padding: 0px 20px;}

    header {height: 60px;}
    header .lt {width: 110px; margin-top: 12px;}
    header .container {padding: 0px 20px;}
    header .rt {margin-top: 9px; gap:0px;}
    header .rt .lk1 {position:relative; background-position: center; background-size: 70%; margin-right: 20px;}
    header .rt .lk1:after {content: ''; position: absolute; top: 5px; right: -10px; width: 1px; height: 30px; background-color: #C5C5C5; opacity: 0.5;}
    header .rt .lk2 {padding: 0px; width: 41px; height: 41px; background-position: center; background-size: 70%;}
    header .rt .lk2 span {display: none;}
    header .rt .btn_menu {margin-left:10px;}
    header .rt .btn_menu span {display: none;}
    .menu-open header .container {padding-right:20px;}
   
    .scroll header {height: 60px;}  
    .scroll header .lt {width: 110px; margin-top: 12px;}  
    .scroll header .rt {margin-top: 9px;}
    

    .popup_call {padding:10px;}
    .popup_call .ct {transform: unset; transform-origin: unset;}
    .popup_call .ct2 {padding:60px 20px 20px 20px;}
    .popup_call .rt {padding:60px 20px 20px 20px;}
    .popup_call .rt .t1 {font-size: 20px; text-align: center;}
    .popup_call .rt .t2 {font-size: 14px; text-align: center;}
    .popup_call .rt .agree .r {font-size: 12px;}
    .popup_call .rt button {font-size: 18px; height: 50px;}
    .popup_call .rt .captcha .pc {width: 65px;}
    .popup_call .con1 {font-size: 20px;}
    .popup_call .con2 {font-size: 12px;}
    .popup_call .con2 {margin-bottom: 24px;}
    .popup_call .con2:after {bottom: -10px;}
    .popup_call .con3 {font-size: 16px;}
    .popup_call.crapply .rws {flex-direction: column; gap:0px;}
    .popup_call.crapply .inputfile .lk .btn_1 {padding-top:13px;}
    .popup_call.viewdetail .lt .t1 {font-size: 24px;}
    .popup_call.viewdetail .lt .t2 {font-size: 16px;}
    .popup_call.viewdetail .rt .t1 {font-size: 20px; text-align: left; margin-top: 30px;}
    .popup_call.viewdetail .rt .t2 {text-align: left;}
    

    .btext {font-size: 14px; line-height: 1.7;}
    .btext h1 {font-size: 16px; margin: 20px 0px 10px 0px;}
    .btext ul li {padding-bottom: 4px;}

    .pgHome .container {padding: 0px; max-width: 100% !important;}
    .pgHome .title {padding:0px 20px;}
    .pgHome.pgCon .container {padding:0px 20px;}

    .hp_sec1 {margin-bottom:40px;}
    .hp_sec2 {margin-bottom:40px;}
    .hp_sec3 {margin-bottom:40px;}
    .hp_sec4 {margin-bottom:40px;}
    .hp_sec5 {margin-bottom:40px;}

    body {padding-top:60px;}

    .banner {margin-bottom: 30px;}
    .banner .item {max-height: calc(100vh - 124px);}
    .banner .item .tx {font-size: 30px; left: 0px; top: auto; width: 100%; text-align: center; bottom: 30px; padding: 0px 20px;}
    .banner .item .tx br {display: none;}
    .banner .item .pc {position: relative;}
    .banner .item .pc:after {content: ''; position: absolute; bottom: 0px; left: 0px; width: 100%; height: 100%; background:url(/public/images/grad.svg) repeat-x left bottom;}
    .banner .owl-dots{bottom:10px !important;}
    .banner .owl-dots .owl-dot span {height: 6px !important; width: 10px !important;}
    .title h1 {font-size: 20px; line-height: 28px; margin-bottom: 10px;}
    .landing .title h1.assesmemt-title{font-size: 20px !important; line-height: 24px; margin-bottom: 0px !important;}
    .title h2 {font-size: 20px; line-height: 28px; margin-bottom: 10px;}
    .title h3 {font-size: 14px; line-height: 18px; margin-bottom: 20px;}


    .topbanner {max-height: calc(100vh - 124px);}
    .topbanner .pc {max-height: calc(100vh - 124px); overflow: hidden;}
    .topbanner .pc:after {content: ''; position: absolute; bottom: 0px; left: 0px; width: 100%; height: 100%; background:url(/public/images/grad.svg) repeat-x left bottom;}
    .topbanner.lp1 .pc:after {content: ''; position: absolute; bottom: 0px; left: 0px; width: 100%; height: 100%; background:no-repeat; }

    .topbanner .container {max-height: calc(100vh - 124px);}

    .topnav.scroll {top:60px;}
    /* Css Changes on 21 apr */
    .topnav2.scroll {top:60px;}
    /*  */

    .hp_sec1 .item .cnt {position:relative; display: block;}
    .hp_sec1 .item .dv:nth-child(2) {position: absolute; top: 0px; background: #ffffff; width:100%; height: 100%; animation: pulse 8s infinite;}
    .hp_sec1 .item .t1 {font-size: 16px; margin-bottom: 10px;}
    .hp_sec1 .item .dv .t2 {font-size: 14px; line-height: 18px;}
    .hp_sec1 .item .dv .pc {margin-bottom: 10px;}
    .hp_sec2 .tx {font-size: 18px; line-height: 22px; bottom: 20px;}

    .hp_sec3 .tx {padding: 20px 20px 30px 20px;}
    .hp_sec3 .tx .t1 {font-size: 20px;}
    .hp_sec3 .tx .t2 {font-size: 14px; line-height: 18px; font-weight: 600;}
    .hp_sec4 {padding-top: 30px; padding-bottom: 60px;}
    .hp_sec4:before {height: 100%;}
    .hp_sec5 .tx {font-size: 13px; line-height: 20px;}
    .hp_sec5 .tx a {background-position: top 5px right 0px;}
    .btn_1 {font-size: 14px;}
    .hp_sec2 .item {border-radius: 15px;}
    .hp_sec3 .pc {border-radius: 15px;}
    .hp_sec3 .item {border-radius: 15px;}
    .hp_sec4 .pc {border-radius: 15px 15px 0px 0px;}
    .hp_sec5 .item {border-radius: 15px; padding: 10px;}

    .topbanner .tx {font-size: 30px; width: 100%; text-align: center; left: 0px; padding: 0px 20px; bottom: 20px; max-width: 100%;}
    .topbanner h1 {font-size: 30px; width: 100%; text-align: center; left: 0px; padding: 0px 20px; bottom: 20px; max-width: 100%;}


    .pgAbout .title {text-align: center; margin-bottom:20px;}
    .pgAbout .sec1 .title h2{font-size: 16px;  line-height: 20px;}
    .pgAbout .sec1 .title h2 span{font-size: 19px;}
    .pgAbout .sec1 {padding: 40px 0px; margin-bottom: 20px;}
    .pgAbout .sec1 .tx {font-size: 14px; line-height: 24px;}
    .pgAbout .sec2 .container {padding: 0px;}
    /* Css Changes on 21 apr */
    .pgAbout .sec2 .item {margin-left: 16px;}
    /*  */
    .pgAbout .sec2 .item img {height: 200px;}
    .pgAbout .sec3 .t3 {font-size: 14px; line-height: 24px;}
    .pgAbout .sec3 .t1 {font-size: 16px; text-align: center; margin-bottom: 6px;}
    .pgAbout .sec3 .t2 {font-size: 19px; line-height: 26px; text-align: center; margin-bottom: 10px;}
    .pgAbout .sec3 .lt {margin-bottom:30px;}
    .pgAbout .sec4 {padding: 40px 0px 0px 0px;}
    .pgAbout .sec4 .tx {font-size: 14px; line-height: 24px;}
    .pgAbout .sec4 .tx p {margin-bottom: 20px;}
    .pgAbout .sec4 .container {padding: 0px;}
    .pgAbout .sec4 .title {padding: 0px 20px;}
    .pgAbout .sec4 .tx {padding: 0px 20px;}
    .pgAbout .sec4 .item .dv .t2 {font-size: 14px;}
    .pgAbout .sec5 .container {padding: 0px;}
    .pgAbout .sec5 .item .dv .t2 {margin-top:10px; font-size: 14px; line-height: 1.5;}
    .pgAbout .sec3 .t3 p {margin-bottom: 20px;}

    .section {margin-bottom: 40px;}
    .breadcrumbs {padding: 20px 0px;}

    .pgLocation .sec1 .ct {border-radius: 15px; padding: 30px 20px;}
    .pgLocation .sec1 .title {text-align: center;}
    .pgLocation .sec1 .title h2 {margin-bottom: 6px;}
    .pgLocation .sec1 .title h3 {font-size: 16px; margin-top: 0px; margin-bottom: 26px;}
    .pgLocation .sec1 .grd {font-size: 14px;}
    .pgLocation .sec1 .grd .dv .bx input[type=text] {font-size: 14px;}
    .pgLocation .sec1 .grd .dv:nth-child(2), .pgLocation .sec1 .grd .dv:nth-child(4) {padding: 10px 0px; font-size: 16px;}
    .pgLocation .sec1 .grd .dv .btn_1 {font-size: 20px; padding-top:15px;}
    .pgLocation .sec1 .grd .dv .bx .l {padding-top: 19px;}
    .pgLocation .sec2 .title h3 {font-size: 18px;}
    .pgLocation .sec2 .lks a {font-size: 16px; font-weight: 600; height: 48px; padding-top: 16px;}
    .pgLocation .sec2 .minheight {min-height: unset;}
    .pgLocation .sec2 .item { max-width: 450px;}

    .pgProduct .sec1 .container {padding:0px;}
    .pgProduct .sec2 .container {padding:0px;}
    .pgProduct .sec3 .container {padding:0px;}
    .pgProduct .sec4 .container {padding:0px;}
    .pgProduct .sec5 .container {padding:0px;}
    .pgProduct .sec6 .container {padding:0px;}
    .pgProduct .sec7 .container {padding:0px;}
    .pgProduct .title {padding:10px 20px 0px 10px;}
    .pgProduct .sec8 .title {padding:0px;}
    .pgProduct .sec9 .title {padding:0px;}
    .pgProduct .title {text-align: left;}
    .pgProduct .title h2 {margin-bottom: 0px;}
    /* .bxs{margin-bottom:16px !important;} 
    .agrees{margin:0px auto 16px 0px !important;} */
    
    .pgProduct .section {margin-bottom: 0px; padding-top: 40px; padding-bottom: 40px;}
    .pgProduct .sec1 {padding-top: 0px;}
    
    .pgProduct .sec2 {padding-top: 0px;}
    .pgProduct .sec2 .t1 {font-size: 22px;}
    .pgProduct .sec2 .t2 {font-size: 14px;}
    .pgProduct .sec2 .t4 {font-size: 13px; padding: 8px 16px;}
    .pgProduct .sec2 .pr .t5 {font-size: 18px;}
    .pgProduct .sec2 .pr .t6 {font-size: 18px;}
    .pgProduct .sec2 .pr .t5 {margin-right:20px;}
    .pgProduct .sec2 .t7 {font-size: 14px; margin-bottom: 20px;}
    .pgProduct .sec2 .li {font-size: 14px; background-position-y: 2px; background-position-x: 0px; background-size: 20px; padding: 4px 0px 10px 30px;}
    .pgProduct .sec2 .t8 {font-size: 14px;}
    .pgProduct .sec2 .t9 {font-size: 13px;}

    .pgProduct .sec3 .bx .t1 {font-size: 12px; margin-bottom: 10px;}
    .pgProduct .sec3 .bx .ct .t2 {font-size: 15px;}
    .pgProduct .sec3 .item {padding:10px;}

    .pgProduct .sec4 .lt {width: 100%;}
    .pgProduct .sec4 .title .pc {margin: 0px auto 40px auto;}
    .pgProduct .sec4 .rt .dv .tx .t1 {font-size: 22px;}
    .pgProduct .sec4 .rt .dv .tx .t2 {font-size: 14px;}

    .pgProduct .sec5 .tx {flex-direction: column;}
    .pgProduct .sec5 .tx .l {font-size: 20px; margin-bottom: 16px;}
    .pgProduct .sec5 .tx .r .t1 {font-size: 16px; margin-bottom:2px;}
    

    .pgProduct .sec6 .nm_ct {padding: 10px 0px 0px 50px; min-height: 40px;}
    .pgProduct .sec6 .nm_ct .pc {width: 40px; height: 40px;}
    .pgProduct .sec6 .nm_ct .nm {font-size: 16px;}
    .pgProduct .sec6 .tx {font-size: 13px;}
    .pgProduct .sec6 .item {padding: 20px;}
    .pgProduct .sec6 .st {margin-bottom: 20px; width: 110px;}
    
    .pgProduct .sec7 .t1 {font-size: 14px;margin-bottom: 10px;}
    .pgProduct .sec7 .t2 {font-size: 12px;}
    .pgProduct .sec7 .lbl {top: 10px; left: 10px; font-size: 12px; padding: 6px 10px;}

    .pgProduct .sec8 table td {font-size: 12px;}
    .pgProduct .sec8 table .hd td {font-size: 12px; padding:10px;}
    .pgProduct .sec8 table td:nth-child(1) {padding:10px 10px 10px 0px;}
    .pgProduct .sec8 table td:nth-child(2) {width:90px;}
    .pgProduct .sec8 table td:nth-child(3) {width:90px;}
    .pgProduct .sec8 table td:nth-child(4) {width:90px; }
    .pgProduct .sec8 table tr:nth-child(2) img {max-width: unset; width: 40px;}

    .pgProduct .sec9 .title h2 span {display: block;}
    .pgProduct .sec9 .acc_btn {font-size: 18px;}
    .pgProduct .sec9 .acc {padding:20px 0px;}
    .pgProduct .sec9 .acc_btn:after {top: -9px;}
    .pgProduct .sec9 .ct {margin-top:20px;}
    .pgProduct .sec9 .acc_tx {font-size: 14px;}

    .pgProduct .sec7 .pc {margin-bottom:20px;}

    .pgProduct.pgOpen .sec16 .grd .dv {width:100%; padding-right: 0px !important;}
    .pgProduct.pgOpen .title {padding:0px;}
    .pgProduct.pgOpen .pc_tx_r {margin-bottom:30px;}
    .pgProduct.pgOpen .title h2 {padding-bottom:10px;}
    .pgProduct.pgOpen .title .tx {font-size: 16px;}
    .pgProduct.pgOpen .sec14 .acc_btn {font-size: 16px;}
    .pgProduct.pgOpen .sec14 .acc_tx {font-size: 14px;}
    .pgProduct.pgOpen .sec16 .title {margin-bottom: 10px;}
    .pgProduct.pgOpen .sec16 .play img {width: 70px;}
    .pgProduct.pgOpen .sec16 .grd .dv {margin-bottom: 20px;}
    .pgProduct.pgOpen .sec16 .grd .dv .t1 {font-size: 20px; padding-top: 10px;}
    .pgProduct.pgOpen .sec16 .grd .dv .t2 {font-size: 18px;}

    .pgMedia .sec2 .tx3 {font-size: 24px;}
    .pgMedia.pgBlog .sec2 .item {border: none; padding: 0px;}

    
    .pgBlog .sec1 .lk {font-size: 16px;}
    .pgBlog .sec1 .tx3 {font-size: 14px;}

    .pgBlog.pgDr .title h2 {margin-bottom: 0px;}
    .pgBlog.pgDr .title h3 {font-size: 16px;}
    .pgBlog.pgDr .title h4 {font-size: 14px; line-height: 1.7;}

    .title h4 {font-size: 14px; line-height: 1.7; font-weight: 400; margin-bottom: 10px;}

    .pgLocation .sec2 .grd {grid-template-columns: repeat(1, minmax(0, 1fr));}
    .pgMedia .sec2 .grd {grid-template-columns: repeat(1, minmax(0px, 1fr));}
    /* Css Changes on 21 apr  */
    .pgMedia .sec3 .grd {grid-template-columns: repeat(1, minmax(0px, 1fr));}
    /*  */
    .pgBlog .sec1 .grd {grid-template-columns: repeat(1, minmax(0px, 1fr));}

    .pgBlog.pgLocDr .sec1 .rt {font-size: 16px;}
    .pgBlog.pgLocDr .sec1 .rt .t1 {text-align: center;}
    .pgBlog.pgLocDr .sec1 .rt h1 {text-align: center;}

    .pgBlog.pgLocDr .sec1 .rt .t2 {text-align: center;}
    .pgBlog.pgLocDr .sec1 .rt .bx {font-size: 16px;}
    .pgBlog.pgLocDr .sec1 .rt .bx .it .t4 {font-size: 20px;}
    .pgBlog.pgLocDr .sec1 .rt .t1 {font-size: 26px;}
    .pgBlog.pgLocDr .sec1 .rt h1 {font-size: 26px;}

    .pgBlog.pgLocDr .sec1 .rt .t2 {font-size: 16px;}
    .pgBlog.pgLocDr .sec1 .rt .bx {padding:10px;}

    .pgBlog.pgDetail .sec0 .lft .h1 {font-size: 18px;}
    .pgBlog.pgDetail .sec0 .lft h1 {font-size: 18px;}

    .pgBlog.pgDetail .sec0 .lft .tp_ct {font-size: 14px; flex-direction: column; align-items: start;}
    .pgBlog.pgDetail .sec0 .lft .tp_ct .r {margin: 20px auto 0px 0px; }
    .pgBlog.pgDetail .sec0 .lft .tp_ct .l {padding-left: 43px;}
    .pgBlog.pgDetail .sec0 .lft .tp_ct .l img {width:34px; height:34px; top:2px;}
    .pgBlog.pgDetail .sec0 .lft .smo {transform: scale(0.8);}
    .pgBlog.pgDetail .sec0 .lft .ct {padding: 20px;}
    .pgBlog.pgDetail .sec0 .lft .ct .tx {font-size: 24px; margin-bottom: 10px;}
    .pgBlog.pgDetail .sec0 .lft .ct a {font-size: 14px;}
    .pgBlog.pgDetail .sec0 .btext .h1 {font-size: 18px; margin-top: 40px; margin-bottom: 20px;}
    .pgBlog.pgDetail .btext {font-size: 14px;}
    .pgBlog.pgDetail .sec0 .btext h2 {font-size: 16px; margin-bottom: 20px;}
    .pgBlog.pgDetail .popup_call .rt .t1 {text-align: left;}
        
    .pgCareer .sec1 .tp {margin-bottom:20px; margin-top:20px; border: none; padding:0px;}
    .pgCareer .sec1 .tp .ct {flex-direction: column; gap:20px;}
    .pgCareer .sec1 .tp .ct:after {display:none;}
    .pgCareer .sec1 .tp .lk {position:relative; right: auto; width:100%; max-width: 240px; margin-top:20px; color:#ffffff; text-transform: uppercase; height:50px; border-radius: 25px; font-weight: 500; text-align: center; padding-top:14px; background-size: 24px; background-position: calc(50% - 38px) 50%;}
    .pgCareer .sec1 .tp .lk span {display: block; padding-left:30px;}
    .pgCareer .sec1 .tp .ct .dv {border: 1px solid #D6D6D6; border-radius: 10px; width: 100%; padding:10px 20px 4px 20px;}
    .pgCareer .sec1 .lt .ct {padding:20px;}
    .pgCareer .sec1 .lt .t1 {font-size: 16px;}
    .pgCareer .sec1 .lt .ac .ac_bt {font-size: 16px; font-weight: 500;}
    .pgCareer .sec1 .lt .ac .ac_tx {font-size: 14px;}
    .pgCareer .sec1 .rt .bx {padding:20px;}
    .pgCareer .sec1 .rt .bx .btn_1 {position: relative; top: 0%; right: auto; width: 140px; transform: none; margin-top: 10px;}
    .pgCareer .sec1 .rt .bx .t1 {font-size: 18px;}
    .pgCareer .sec1 .rt .bx .t2 {flex-direction: column; gap:10px;}
    .pgCareer .sec1 .rt .bx .t3 {font-size: 14px;}
    .pgCareer .sec1 .title h2 {max-width: 460px;}

    /* CSS Changes on 21 apr  */
    .landing .sec1 {padding-top: 0px !important; padding-bottom: 0px;}
    .landing .sec1 .container {padding:0px 20px;}
    .landing .sec2 {padding-top: 40px;}
    .landing .sec2 .container {padding:0px 20px;}
    .landing .sec2 .fd {flex-direction: column; gap: 30px;}

    .smiletest .topnav2 {top:60px;} 

    .thank .sec1 {padding-bottom: 60px;}
    .thank .sec1 .container {flex-direction: column; gap: 20px;}
    .thank .sec1 .lt {width: 200px;}
    .thank .sec1 .rt {width: 100%;}
    .thank .sec1 .rt br {display: none;}


    .pgMedia .sec3 .tx {font-size: 18px; padding-bottom: 10px;}
    .pgMedia .sec3 .tx2 {font-size: 14px;}
    .pgMedia .sec3 .tx3 {font-size: 14px;}

    /*  */

    footer {padding: 0px 20px 65px 20px;}
    footer .tp .container {max-width: 100%; padding:0px;}
    footer .tp {padding: 40px 0px 30px 0px;}
    footer .tp .dv button { font-size: 14px; height: 40px; width: 130px; border-radius: 8px;}
    footer .md {padding: 30px 0px 0px 0px;}
    footer .md .container {max-width: 100%; padding:0px;}
    footer .md .grd .dv {float:left; width:50% !important; clear:none !important; margin-bottom: 30px;}
    footer .md .grd .dv a {font-size: 12px; line-height: 18px;}
    footer .md .grd .dv:nth-child(2n+1) {clear:both !important;}
    footer .md .grd .dv:nth-child(5) {width:100% !important; border-top: 1px solid #484848; padding-top:40px; text-align: center; display: flex; align-items: center;}
    footer .md .grd .dv:nth-child(5) .ttl {font-size: 18px;}
    footer .bt {font-size: 12px; line-height: 22px; color: #aaaaaa;}
    footer .bt a {color: #aaaaaa;}
    footer .bt .container {flex-direction: column; padding:0px;}
    footer .bt .container div {width: 100%;}

    .ftr_mobile {display: flex;}
	
	
	 .aligner-wrapper .row{
        grid-template-columns: 1fr;
    }
    .aligner-wrapper .mw-630, .aligner-wrapper.aligner-2-0 .mw-630{
        padding-left: 0;
        padding-right: 0;
    }
    .aligner-wrapper .mw-630 ul{
        text-align: left;
        margin-left: 0;
    }
    .aligner-wrapper .mw-630 ul li{
        font-size: 14px;
    }
    .aligner-wrapper .mw-630 ul li::before{
        width: 20px;
    }
    section.section.aligner-wrapper{
        padding-top: 0;
    }
    section.section.sec3.rev .rt{
        padding:0 20px;
    }
    section.section.sec3.rev .lt{
        padding:0 20px;
    }
    section.section.sec3.rev .rt .t1 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 10px;
    }
    section.section.sec3.rev .rt .t2{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
    }
    section.section.aligner-wrapper .col-50 .title {
padding: 0px;
    }
    section.section.aligner-wrapper.aligner-2-0 .row{
flex-direction: column-reverse;
display: flex;
    }
    section.aligner-wrapper .title h2{
        font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
    }
    section.aligner-wrapper .title h3{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
    }
    .aligner-wrapper .mw-630 ul li {
        font-size: 14px;
        background-position-y: 2px;
        background-position-x: 0px;
        background-size: 20px;
        padding: 4px 0px 10px 30px;
    }
    .highlight-text{
        padding:20px 16px;
        font-size: 15px;
    }
    .aligner-wrapper .mw-630 p {
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 19px;
    }
    .content-cms{
        max-width: 100%;
        padding: 0px 20px;
        padding-bottom: 50px;
    }

   
}

@media(max-width:600px){
    .popup_video video {height: unset; width: 100%;}
    .popup_video .dv {width:85%; height: auto;}
    /* CSS Changes on 21 apr */
    .landing .sec20 .container {grid-template-columns: repeat(1, minmax(0, 1fr));}
    .landing .sec20 .dv {border: none; border-bottom: 1px dashed #666 !important; padding-bottom: 20px; margin-bottom: 20px;}
    .landing .sec20 .dv:last-child {border:none !important;}
    .smiletest .sec1 .bx.opt3 {grid-template-columns: repeat(2, minmax(0, 1fr));}

    /*  */
}

@media(max-width:550px){
    .pgCon .grd {grid-template-columns: repeat(1, minmax(0, 1fr)); gap: 40px; margin-top: 20px;}
    .pgCon .hp_sec3 .tx {padding:20px 20px 0px 20px;}
    /* CSS Changes on 21 apr */
    .smiletest .sec1 .bx {font-size: 14px;}
    .smiletest .sec1 .bx .dv .img_cnt {width: 100%;}
    .smiletest .sec1 .bx .dv .img_cnt img {width: 100%;}
    .smiletest .sec1 .bx .dv div {width: 100%;}
    .smiletest .sec1 .bx.opt1 {grid-template-columns: repeat(2, minmax(0, 1fr));}
    /*  */

}


@media(max-width:450px){
    .pgProduct .sec3 .bx .ct {flex-direction: column;}
    .pgProduct .sec3 .bx .ct .pc {width:100%;  max-width: 273px; margin: 0px 0px 10px;}
    .pgProduct .sec3 .bx .ct .t2 {width:100%; padding:10px 0px 0px 0px; text-align: center;}
    .pgProduct .sec3 .bx .t1 {margin:0px auto 10px auto; float: none; display: grid;}
    .pgProduct .sec3 .item {text-align: center;}
    .pgProduct .sec4 .rt .dv {flex-direction: column;}
    .pgProduct .sec4 .rt .dv .pc {width: 100%; margin-bottom:20px;}
    .pgProduct .sec4 .rt .dv .tx {padding:0px;}
}



@media(max-width:370px){
    .pgProduct .sec2 .lks {gap: 10px; flex-direction: column;}
    .pgProduct .sec8 table td {font-size: 10px;}
    .pgProduct .sec8 table .hd td {font-size: 10px;}
    .pgBlog.pgLocDr .sec1 .lt .btn_1 {font-size: 12px; padding-top: 15px;}
    .mb_menu.active {width: 300px;}
}


@media(max-width:340px){
    header .rt .btn_menu {margin-left: 0px;}
    header .rt .lk1 {margin-right: 6px;}
    header .rt .lk1:after {display: none;}
}


@media(min-width:1200px){
    header .md .mn:hover .lk {color:#7d65ac;}
    header .md .mn.has_menu:hover:after {background-image:url(/public/images/ar_down_active.png)}
    header .md .mn:hover .mn_lk {display: block;}
    .topnav .container .lk:hover {color: #ffffff;  background-color: #7d65ac;}
}

@media(min-width:980px){
    
    .btn_1:hover {background-color: #000000;}
    header .rt .lk1:hover {background-color: #000000;}
    header .rt .lk2:hover {background-color: #000000;}
    .banner .owl-dots .owl-dot span:hover {background-color: #ffffff;}
    .hp_sec1 .item  .dv:hover .pc img {transform: scale(1.1);}
    header .md .mn .mn_lk a:hover {color:#ffffff; background-color: #7d65ac;}
    .hp_sec2 .item:hover .pc img {transform: scale(1.1);}
    .hp_sec2 .item:hover .tx {left:0px;}
    .hp_sec2 .item:hover .grad {opacity:1;}
    .hp_sec3 .item:hover .pc img {transform: scale(1.1);}
    .hp_sec3 .item:hover .tx .t2 {margin-bottom: 0px;}
    .hp_sec3 .item:hover .tx .lk {opacity:1;}
    .hp_sec3 .item:hover .grad {left:0px;}
    .hp_sec4 .item:hover .pc img {transform: scale(1.1);}
    .hp_sec4 .item:hover .play:before {transform: scale(1); opacity: 1;}
    .hp_sec5 .item:hover {border-color: #7D65AC;}
    .hp_sec5 .item:hover .pc img {transform: scale(1.1);}
    .pgAbout .sec4 .item:hover img {transform: scale(1.1);}
    .pgAbout .sec3 .lt:hover img {transform: scale(1.1);}
    .pgAbout .sec2 .item:hover img {transform: scale(1.1);}
    .pgProduct .sec5 .item:hover .pc img {transform: scale(1.1);}
    .pgProduct .sec5 .item:hover .play:before {transform: scale(1); opacity: 1;}
    .pgProduct .sec12 .item:hover .pc img {transform: scale(1.1);}
    .pgProduct.pgOpen .sec16 .play:hover:after {transform: scale(1); opacity: 1;}
    .pgMedia .sec2 .item:hover .pc img {transform: scale(1.1);}
    .pgMedia .sec2 .lk:hover {color:#000000;}
    /* CSS Changes on 21 Apr */
    .pgMedia .sec3 .item:hover .pc img {transform: scale(1.1);}
    /*  */
    .pgLocation .sec2 .item:hover .pc img {transform: scale(1.1);}
    .pgBlog .sec1 .item:hover .pc img {transform: scale(1.1);}
    .pgBlog .sec1 .lk:hover {color:#7D65AC;}
    .pgBlog.pgDetail .sec0 .lft .ct a:hover {color:#7D65AC}
    .pgCareer .sec1 .tp .lk:hover {background-color: #000000;}
    /* CSS Changes on 21 Apr */
    .smiletest .sec1 .bx .dv:hover {border-color: #7D65AC; background-color: #7D65AC; color:#fff;}
    .pgProduct .sec2 .lks a:hover:nth-child(1) {border: 1px solid #7D65AC; color: #7D65AC;}
    .pgLocation .sec2 .lks a:hover:nth-child(1) {border: 1px solid #7D65AC; color: #7D65AC;}
    .pgBlog.pgDetail .sec0 .lft a.tg:hover {background-color: #7D65AC; color:#ffffff;}
    /*  */
}
.swiper-slide {height:auto !important;}


footer .md .grd .dv:last-child {width: 170px; padding-right: 0px;  float: right;}

footer .md .grd .dv:nth-child(2) {width: 25%;}

@media(max-width:1200px){

	footer .md .grd .dv:last-child {width: 33.33%; padding-right: 0px; float: left;}

	footer .md .grd .dv:nth-child(2) {width: 33.33%;}

}
 

    